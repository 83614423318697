import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#5a2af5",
      secondary: "#3D3E8F",
    },
    mailvibes: {
      main: "#5a2af5",
      secondary: "#969cff",
      backgroundPage: "#f2f2f3",
      tertiary: "#aaa0f8",
      fourting: "#f2f1fe",
      lighter: "#e5e2fd",
      darker: "#7e6ff4",
    },
    grays: {
      main: "#ced4da",
      primary: "#ced4da",
      secondary: "#dee2e6",
      dark30: "#dadde1",
      dark40: "#a3a3a3",
      dark50: "#adadad",
      dark70: "#606770",
      dark80: "#b8b8b8",
      dark100: "#767676",
      dark150: "#464b53",
      dark200: "#494c52",
      dark300: "#585858",
    },
    white: "#fff",
    black: {
      main: "#000",
    },
  },
  MuiTab: {
    root: {
      "&:hover": {
        backgroundColor: "#5a2af5",
        color: "white",
      },
      "&$selected": {
        backgroundColor: "#5a2af5",
        color: "white",
        "&:hover": {
          backgroundColor: "#5a2af5",
          color: "white",
        },
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        input: {
          "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px white inset",
          },
        },
      },
    },
  },
});

export default theme;
