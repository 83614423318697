import { useId } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";

export const SelectInput = (props) => {
  const {
    label,
    items,
    helperText,
    size = "small",
    sx = {},
    fullWidth = false,
    ...rest
  } = props;
  const labelId = useId();
  return (
    <>
      <FormControl
        sx={{ minWidth: 120, ...sx }}
        size={size}
        fullWidth={fullWidth}
      >
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select labelId={labelId} id={labelId} label={label} {...rest}>
          {items.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.text}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </>
  );
};
