import { mailvibesApi, refreshAccessToken } from "./";
import { setItem, getItem } from "../utils";

export const configureAxiosInterceptor = async (data) => {
  let auth = getItem("auth") || data;
  mailvibesApi.interceptors.request.use(
    (config) => {
      if (auth?.user?.access_token) {
        config.headers["Authorization"] = auth.user.access_token;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  mailvibesApi.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const config = error.config;
      if (error.response.status === 401 && !config?._retry && auth) {
        config._retry = true;

        const newAuth = await refreshAccessToken(auth);
        auth = newAuth;
        setItem("auth", newAuth);
        config.headers["Authorization"] = newAuth?.user?.access_token;

        return mailvibesApi.request(config);
      }
      return Promise.reject(error);
    }
  );
};
