import { Box, Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

export const EmptyContent = ({
  img,
  title,
  handleAction,
  buttonLabel,
  secondaryButtonLabel,
  handleActionSecond,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{ height: "70vh" }}
    >
      <img src={img} alt={title} />
      <Typography variant="h5">{title}</Typography>
      <Box display="flex">
        {handleAction && (
          <Button
            onClick={handleAction}
            startIcon={<AddIcon />}
            variant="contained"
            sx={{ marginTop: 3 }}
          >
            {buttonLabel}
          </Button>
        )}
        {handleActionSecond && (
          <Button
            onClick={handleActionSecond}
            startIcon={<CalendarMonthIcon />}
            variant="contained"
            sx={{ marginTop: 3, marginLeft: 2 }}
          >
            {secondaryButtonLabel}
          </Button>
        )}
      </Box>
    </Box>
  );
};
