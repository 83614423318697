import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotes, newNote } from "../redux/features/NotesSlice";
import {
  IconButton,
  Button,
  Menu,
  Box,
  TextareaAutosize,
  Paper,
  CircularProgress,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import CloseIcon from "@mui/icons-material/Close";
import { Notes } from "./";

const NotesContainer = ({ handleClose, notes }) => {
  const notesState = useSelector((state) => state.notes);
  const dispatch = useDispatch();
  const [textAreaValue, setTextAreaValue] = useState("");
  const [send, setSend] = useState(false);

  useEffect(() => {
    if (notes?.length > 0) {
      dispatch(fetchNotes(notes[0].mail_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (textAreaValue !== "") {
      setSend(true);
    } else {
      setSend(false);
    }
  }, [textAreaValue]);

  const handleClickSend = () => {
    if (textAreaValue !== "" && textAreaValue !== null) {
      dispatch(newNote({ mail_id: notes[0].mail_id, note: textAreaValue }));
      setSend((prev) => !prev);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <IconButton
          sx={{ marginLeft: "auto", marginRight: "12px" }}
          onClick={handleClose}
        >
          <CloseIcon color="grays" />
        </IconButton>
        <Box
          sx={{
            padding: "1rem",
            paddingBottom: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", width: "100%" }}>
            <TextareaAutosize
              onChange={(event) => setTextAreaValue(event?.target?.value)}
              value={textAreaValue}
              maxRows={7}
              style={{ width: "100%", height: 100, margin: 4 }}
            />
            {send && (
              <IconButton
                onClick={handleClickSend}
                sx={{ height: "fit-content", alignSelf: "center" }}
              >
                <SendIcon color="primary" />
              </IconButton>
            )}
          </Box>
          <Box
            sx={{
              marginTop: "1rem",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxHeight: "245px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {notesState?.loading ? (
              <CircularProgress color="primary" sx={{ margin: "2rem" }} />
            ) : (
              notesState?.notes?.map((note) => (
                <Notes note={note} key={note.id} />
              ))
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "0.5rem",
          paddingTop: 0,
        }}
      >
        <Button onClick={handleClose}>Cancel</Button>
      </Box>
    </Box>
  );
};

export const DropDownNote = ({ notes }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <StickyNote2OutlinedIcon
          color={notes?.length > 0 ? "primary" : "grays"}
        />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={(event) => event.stopPropagation()}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          padding: 10,
        }}
      >
        <Paper sx={{ width: "359px" }} elevation={0}>
          <NotesContainer handleClose={handleClose} notes={notes} />
        </Paper>
      </Menu>
    </>
  );
};
