const getQueryParamEnv = (prefix = "?") => {
  let param = "";
  switch (process.env.NODE_ENV) {
    case "production":
      param = "";
      break;
    case "test":
      param = prefix + "env=test";
      break;
    case "development":
      param = prefix + "env=dev";
      break;
    default:
      param = prefix + "env=dev";
  }
  return param;
};

export default getQueryParamEnv;
