import { RouterProvider } from "react-router-dom";
import router from "./routes/router";
import { Toaster } from "react-hot-toast";
import { configureAxiosInterceptor } from "./api/axiosInterceptor";

const App = () => {
  configureAxiosInterceptor();
  return (
    <>
      <RouterProvider router={router} />
      <Toaster />
    </>
  );
};

export default App;
