import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Tooltip,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { GoogleLogoutButton } from "./GoogleLogoutButton";
import { menuSettingsOptions } from "../constants";

export const ProfileMenu = () => {
  const [menuAnchor, setMenuAnchor] = useState();
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleOpenMenu = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const onClickNavigate = (link) => {
    handleCloseMenu();
    navigate(`${link}`);
  };

  return (
    <div>
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title="Open settings">
          <IconButton onClick={handleOpenMenu} sx={{ p: 0 }}>
            <Avatar alt="Remy Sharp" src={auth?.user?.picture} />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={menuAnchor}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          open={Boolean(menuAnchor)}
          onClose={handleCloseMenu}
        >
          {menuSettingsOptions.map((setting) => (
            <MenuItem
              key={setting.name}
              onClick={() => onClickNavigate(setting.link)}
            >
              <Typography textAlign="center">{setting.name}</Typography>
            </MenuItem>
          ))}

          <MenuItem onClick={handleCloseMenu}>
            <GoogleLogoutButton />
          </MenuItem>
        </Menu>
      </Box>
    </div>
  );
};
