import { Box, Typography } from "@mui/material";
import MUXSwitch from "./MUXSwitch";
import { theme } from "../theme";

/**
 *
 * @param {object} props
 * @returns {JSX.Element}
 */
export const LargeSwitchDescription = ({
  icon,
  title,
  isActive,
  setIsActive,
}) => {
  const handleChecked = () => {
    setIsActive();
  };

  return (
    <Box
      sx={{
        padding: "0.3rem",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex" }}>
          {icon}
          <Typography
            variant="h6"
            sx={{
              fontSize: "16px",
              fontWeight: "bold",
              color: theme.palette.grays.dark150,
              marginLeft: "5px",
            }}
          >
            {title}
          </Typography>
        </Box>

        <MUXSwitch
          onChange={handleChecked}
          checked={isActive}
          label={isActive ? "Activated" : "Disabled"}
          labelPlacement="start"
        />
      </Box>
    </Box>
  );
};
