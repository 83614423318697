/**
 * devuelve el nombre del navegador
 * @param {Object}
 * @returns String
 */
function getBrowserName(req) {
  if (req?.user_agent?.ua?.includes("GoogleImageProxy")) {
    return "Google";
  } else if (req?.user_agent?.ua?.includes("YahooMailProxy")) {
    return "Yahoo";
  } else if (req?.user_agent?.ua?.includes("MSIE")) {
    return "Outlook";
  } else {
    return req?.user_agent?.browser?.name;
  }
}

export default getBrowserName;
