import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./features/authSlice";
import sentMailsSlice from "./features/sentMails";
import templateSlice from "./features/TemplateSlice";
import settingsSlice from "./features/settingsSlice";
import accountSlice from "./features/accountSlice";
import emailTrackingSlice from "./features/emailTrackingSlice";
import notesSlice from "./features/NotesSlice";
import LinkTracingSlice from "./features/LinkTracingSlice";
import templateListSlice from "./features/templateListSlice";
import distributionListsSlice from "./features/distributionListsSlice";
import distributionSlice from "./features/distributionSlice";
import companaySlice from "./features/companySlice";
import distributionUserSlice from "./features/distributionUserSlice";
import summarizerSlice from "./features/summarizerSlice";
import composeAISlice from "./features/composeAISlice";
import chatSlice from "./features/chatSlice";
import {
  errorHandlingMiddleware,
  authMiddleware,
  sendEmailSuccess,
} from "./middlewares";

const store = configureStore({
  reducer: {
    auth: authSlice,
    sentMails: sentMailsSlice,
    template: templateSlice,
    settings: settingsSlice,
    account: accountSlice,
    emailTracking: emailTrackingSlice,
    linkTracking: LinkTracingSlice,
    notes: notesSlice,
    templateList: templateListSlice,
    distributionLists: distributionListsSlice,
    distribution: distributionSlice,
    distributionUser: distributionUserSlice,
    company: companaySlice,
    summarizer: summarizerSlice,
    composeAI: composeAISlice,
    chat: chatSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authMiddleware)
      .concat(errorHandlingMiddleware)
      .concat(sendEmailSuccess),
});

export default store;
