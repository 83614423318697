import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { mailvibesApi } from "../../api";

const initialState = {
  loading: false,
  company: {},
  error: "",
};

export const getCompany = createAsyncThunk("company/getCompany", async () => {
  return mailvibesApi
    .get("/v2/company/user")
    .then((response) => response.data)
    .catch((error) => error.message);
});

const companySlice = createSlice({
  name: "company",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCompany.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getCompany.fulfilled, (state, action) => {
      state.loading = false;
      state.company = action.payload;
      state.error = "";
    });
    builder.addCase(getCompany.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default companySlice.reducer;
