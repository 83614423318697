import RejectedSvg from "../../assets/rejected.svg";

/**
 * Rejected icon
 * @returns {JSX.Element}
 */
const RejectedIcon = () => {
  return <img src={RejectedSvg} alt="Rejected" />;
};

export default RejectedIcon;
