import {
  homeRouteLink,
  emailTrackingRouteLink,
  massEmailRouteLink,
  sequenceRouteLink,
  mailvibesSettingsRouteLink,
  integrationsRouteLink,
} from "../constants";
import getRoutePathname from "./getRoutePathname";

/**
 * return a specific route
 * @param {object} route
 */
const getRoute = (routeName) => {
  const route = [
    homeRouteLink,
    emailTrackingRouteLink,
    massEmailRouteLink,
    sequenceRouteLink,
    mailvibesSettingsRouteLink,
    integrationsRouteLink,
  ].find((route) => getRoutePathname(route[0]?.link, 1) === routeName);

  if (!route) {
    return homeRouteLink;
  }

  return route;
};

export default getRoute;
