import { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchSentMails } from "../../../redux/features/sentMails";
import { useDebounce } from "../../../hooks/index";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FilterListIcon from "@mui/icons-material/FilterList";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { SelectInput, MUXDateRangePicker } from "../../../components";
import TextField from "@mui/material/TextField";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EmailIcon from "@mui/icons-material/Email";
import EventIcon from "@mui/icons-material/Event";

/**
 * Filter button
 * @param {object} props
 * @returns {JSX.Element}
 */
const FilterButton = ({ rowsPerPage }) => {
  const [fillterButtonAnchorEl, setFilterButtonAnchorEl] = useState(null);
  const isFilterButtonOpen = Boolean(fillterButtonAnchorEl);
  const [filtersControls, setFilterControls] = useState({
    status: "",
    subject: "",
    dateTime: [undefined, undefined],
  });
  const [subjectInput, setSubjectInput] = useState("");
  const debounceSubjectSave = useDebounce({
    setState: setFilterControls,
    prop: "subject",
    timeout: 700,
  });
  const dispatch = useDispatch();

  const handleIsOpen = (event) => {
    setFilterButtonAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setFilterButtonAnchorEl(null);
  };

  const selectStatusHandler = (status) => {
    setFilterControls((prev) => ({ ...prev, status }));
  };

  const subjectInputHandler = (event) => {
    setSubjectInput(event?.target?.value);
    debounceSubjectSave(event.target.value);
  };

  const dateInputHandler = (date) => {
    setFilterControls((prev) => ({ ...prev, dateTime: date }));
  };

  const submitHandler = () => {
    const { status, subject, dateTime } = filtersControls;

    dispatch(
      fetchSentMails({ status, search: subject, date: dateTime, rowsPerPage })
    );
  };

  const resetHandler = () => {
    setFilterControls({
      status: "",
      subject: "",
      dateTime: [undefined, undefined],
    });
  };

  const isDateEmpty =
    filtersControls?.dateTime[0] === undefined ||
    filtersControls?.dateTime[1] === undefined;
  const isSubjectEmpty = filtersControls?.subject === "";
  const isStatusEmpty = filtersControls?.status === "";

  const isOkDisabled = isDateEmpty && isSubjectEmpty && isStatusEmpty;

  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls={isFilterButtonOpen ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isFilterButtonOpen ? "true" : undefined}
        onClick={handleIsOpen}
      >
        <FilterListIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={fillterButtonAnchorEl}
        open={isFilterButtonOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Typography
          component="h4"
          sx={{ margin: 0.7, marginLeft: 2.5, fontWeight: "bold" }}
        >
          Start you search
        </Typography>
        <MenuItem>
          <>
            <RemoveRedEyeIcon color="grays" />
            <SelectInput
              sx={{ width: "270px" }}
              fullWidth
              labelId="main-datatable-status-select-label"
              label="Status"
              id="main-datatable-status-select"
              value={filtersControls?.status}
              onChange={(event) => selectStatusHandler(event.target.value)}
              items={[
                { value: "all", text: "All" },
                { value: "sent", text: "Sent" },
                { value: "scheduled", text: "Scheduled" },
              ]}
            />
          </>
        </MenuItem>
        <MenuItem>
          <>
            <EmailIcon color="grays" />
            <TextField
              sx={{ margin: "8px", width: "270px" }}
              fullWidth
              id="subject-input"
              size="small"
              value={subjectInput}
              onChange={subjectInputHandler}
            />
          </>
        </MenuItem>
        <MenuItem sx={{ padding: "12px 16px" }}>
          <EventIcon sx={{ marginRight: "8px" }} color="grays" />
          <MUXDateRangePicker onChange={dateInputHandler} reset={isDateEmpty} />
        </MenuItem>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: 1.7,
            paddingRight: 2.7,
          }}
        >
          <Button
            onClick={resetHandler}
            color="grays"
            variant="outlined"
            sx={{ marginRight: 2.5 }}
            size="small"
            disabled={isOkDisabled}
          >
            reset
          </Button>
          <Button
            onClick={submitHandler}
            color="primary"
            variant="contained"
            size="small"
            disabled={isOkDisabled}
          >
            ok
          </Button>
        </Box>
      </Menu>
    </>
  );
};

export default FilterButton;
