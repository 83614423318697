export function timeAgo(date) {
  const now = new Date();
  const passTime = new Date(date);
  const secondsElapsed = Math.floor(
    (now.getTime() - passTime.getTime()) / 1000
  );

  if (secondsElapsed < 60) {
    return "A few seconds ago";
  }

  const minutesElapsed = Math.floor(secondsElapsed / 60);

  if (minutesElapsed === 1) {
    return "Less than a minutes ago";
  }

  if (minutesElapsed < 60) {
    return `Less than an ${minutesElapsed} minutes ago`;
  }

  return "Not valid date";
}
