import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { MenuTabs } from "../../components";
import Divider from "@mui/material/Divider";

/**
 * Main content wrapper of the app
 * it will show the options for every section
 * of the app
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {Array} props.routeLinks
 * @returns {JSX.Element}
 */
const Content = ({ children, routeLinks }) => {
  return (
    <Box component="main" sx={{ padding: "20px", height: "88vh" }}>
      <Box>
        <Stack justifyContent="flex-end" direction="row">
          <MenuTabs pages={routeLinks} />
        </Stack>
      </Box>
      <Divider />
      {children}
    </Box>
  );
};

export default Content;
