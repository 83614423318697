import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getItem } from "../../utils/localStorage";
import { mailvibesApi } from "../../api";

/**
 * get data from localstorage
 */
const auth = getItem("auth");

const initialState = {
  user: auth?.user || {},
  authenticated: auth?.authenticated || false,
  loading: false,
  error: "",
};

export const signin = createAsyncThunk("auth/signin", async (token) => {
  return mailvibesApi.post("/v2/auth/google", { token }).then((response) => {
    return {
      ...response.data?.user,
      access_token: response.data.access_token,
      expiresIn: response.data.expires_in,
    };
  });
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.loading = false;
      state.user = {};
      state.authenticated = false;
      state.error = "";
    },
    manualSignin: (state, action) => {
      state.loading = false;
      state.user = action.payload?.user;
      state.authenticated = true;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signin.pending, (state) => {
      state.loading = true;
      state.authenticated = false;
      state.user = {};
      state.error = "";
    });
    builder.addCase(signin.fulfilled, (state, action) => {
      state.user = action.payload;
      state.authenticated = true;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(signin.rejected, (state, action) => {
      state.loading = false;
      state.authenticated = false;
      state.user = {};
      state.error = action.error.message;
    });
  },
});

export default authSlice.reducer;
export const { logout, manualSignin } = authSlice.actions;
