import { useState } from "react";
import { useDispatch } from "react-redux";
import { editNote, deleteNote } from "../redux/features/NotesSlice";
import { Box, IconButton, Typography, TextareaAutosize } from "@mui/material";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { theme } from "../theme";
import { parseDateRelative } from "../utils";

export const Notes = ({ note }) => {
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState(note.note || "");

  const handleEditableTextAreaChange = (event) => {
    setTextAreaValue(event.target.value);
  };

  const handleEditNote = () => {
    dispatch(
      editNote({ noteId: note.id, mail_id: note.mail_id, note: textAreaValue })
    );
    setEditable(false);
  };

  const handleDeleteNote = (event) => {
    event.stopPropagation();
    dispatch(deleteNote(note.id));
  };

  return (
    <>
      <Box
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          padding: "1rem 0",
          paddingBottom: 0,

          "&:hover": {
            cursor: "pointer",
          },
          borderBottom: "1px solid rgba(0,0,0,0.1)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              marginLeft: "5px",
              fontWeight: "600",
              color: theme.palette.grays.dark70,
            }}
            variant="body2"
          >
            closedAcountGroups
          </Typography>
          <Typography
            variant="caption"
            sx={{ color: theme.palette.grays.dark100, paddingRight: "5px" }}
          >
            {(note?.createdAt || note?.updatedAt) &&
              parseDateRelative(note?.createdAt || note?.updatedAt)}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0.5rem 0",
            width: "100%",
          }}
        >
          {editable ? (
            <TextareaAutosize
              onChange={handleEditableTextAreaChange}
              value={textAreaValue}
              maxRows={7}
              style={{ width: "288px", height: 40, margin: 4 }}
            />
          ) : (
            <Typography
              variant="body2"
              sx={{
                marginLeft: "2%",
                marginRight: "auto",
                color: theme.palette.grays.dark100,
              }}
            >
              {note.note}
            </Typography>
          )}
          {(isShown || editable) && (
            <Box
              sx={{
                display: "flex",
                marginLeft: "auto",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {editable ? (
                <IconButton onClick={handleEditNote}>
                  <SendIcon color="primary" />
                </IconButton>
              ) : (
                <>
                  <IconButton
                    sx={{ marginRight: "5px", width: "20px", height: "20px" }}
                    onClick={() => setEditable(true)}
                  >
                    <DriveFileRenameOutlineIcon
                      color="grays"
                      sx={{
                        "&:hover": {
                          color: theme.palette.mailvibes.main,
                        },
                      }}
                    />
                  </IconButton>
                  <IconButton
                    sx={{ width: "20px", height: "20px" }}
                    onClick={handleDeleteNote}
                  >
                    <DeleteIcon
                      color="grays"
                      sx={{
                        "&:hover": {
                          color: theme.palette.mailvibes.main,
                        },
                      }}
                    />
                  </IconButton>
                </>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
