import { summaryOptions } from "../constants";

export const extractCheckboxFromInput = (data) => {
  const { file, language, ...rest } = data;

  const options = Object.entries(rest)
    .filter((option) => option[1] === true)
    .map((option) => summaryOptions[option[0]]);
  if (options.length > 0) {
    const prompt = options.join(", ");

    return {
      topics:
        "Make sure you focus on summarizing the text  based on the following points: " +
        prompt,
      options: options,
    };
  }
};
