import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import NavBar from "./NavBar";
import SideBar from "./Sidbar";
import Toolbar from "@mui/material/Toolbar";
import { Content } from "../../containers";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Outlet } from "react-router-dom";
import getRoutePathname from "../../utils/getRoutePathname";
import getRoute from "../../utils/getRoutes";
import { drawerWidth } from "../../constants";

/**
 * Main Layout component
 * @returns {JSX.Element}
 */
function MainLayout() {
  const responsive = useTheme();
  const isNotMobile = useMediaQuery(responsive.breakpoints.up("sm"));
  const location = useLocation();

  return (
    <Box>
      <CssBaseline />
      <NavBar />
      <SideBar />
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          height: "100vh",
          marginLeft: isNotMobile && `${drawerWidth}px`,
        }}
      >
        <Toolbar />
        <Content routeLinks={getRoute(getRoutePathname(location.pathname, 1))}>
          <Outlet />
        </Content>
      </Box>
    </Box>
  );
}
export default MainLayout;
