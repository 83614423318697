import { useState } from "react";
import { Box, Accordion } from "@mui/material";
import { theme } from "../theme";

import "../styles/collalseWrapper.css";

export const CollapseWrapper = ({ children, open, handleOpen }) => {
  const [isOpen, setIsOpen] = useState(open);

  const handleIsOpen = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Box
      sx={{
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: theme.palette.grays.dark30,
        borderRadius: "7px",
        padding: "0 1rem",
        marginTop: "2rem",
      }}
    >
      <Accordion
        elevation={0}
        expanded={open !== undefined ? open : isOpen}
        onChange={handleOpen !== undefined ? handleOpen : handleIsOpen}
      >
        {children}
      </Accordion>
    </Box>
  );
};
