import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { mailvibesApi } from "../../api";

const initialState = {
  loading: true,
  templates: [],
  pages: 1,
  count: 10,
  error: "",
};

export const getTemplateList = createAsyncThunk(
  "templateList/getTemplateList",
  async ({
    status,
    search,
    date,
    rowsPerPage = 10,
    page = 1,
    order,
    sort = "ASC",
  }) => {
    return mailvibesApi
      .get(
        `/v2.1/template?limit=${rowsPerPage}&page=${page}&mindate=${
          date[0] === undefined ? "" : date[0]
        }&maxdate=${date[1] === undefined ? "" : date[1]}&search=${
          search ? search : ""
        }&status=${status ? status : "all"}&order=${order}&sort=${sort}`
      )
      .then((response) => response.data);
  }
);

export const deleteTemplate = createAsyncThunk(
  "templateList/deleteTemplate",
  async (id) => {
    return mailvibesApi
      .delete(`v2/template/${id}`)
      .then((response) => response.data)
      .catch((error) => error.message);
  }
);

const templateListSlice = createSlice({
  name: "templateList",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getTemplateList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTemplateList.fulfilled, (state, action) => {
      state.loading = false;
      state.templates = action.payload.templates;
      state.pages = action.payload.pages;
      state.count = action.payload.count;
    });
    builder.addCase(getTemplateList.rejected, (state, action) => {
      state.loading = false;
      state.templates = [];
      state.error = action.error.message;
    });
    builder.addCase(deleteTemplate.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteTemplate.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(deleteTemplate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default templateListSlice.reducer;
