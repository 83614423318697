import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { mailvibesApi } from "../../api";

const initialState = {
  loading: false,
  distributions: [],
  pages: 1,
  count: 10,
  error: "",
};

export const getDistributionList = createAsyncThunk(
  "distributionLists/getDistributionLists",
  async ({
    status,
    search,
    date,
    rowsPerPage = 10,
    page = 1,
    order,
    sort = "ASC",
  }) => {
    return mailvibesApi
      .get(
        `/v2.1/distribution?limit=${rowsPerPage}&page=${page}&mindate=${
          date[0] === undefined ? "" : date[0]
        }&maxdate=${date[1] === undefined ? "" : date[1]}&search=${
          search ? search : ""
        }&status=${status ? status : "all"}&order=${order}&sort=${sort}`
      )
      .then((response) => response.data);
  }
);

export const deleteDistribution = createAsyncThunk(
  "distributionLists/deleteDistribution",
  async (id) => {
    return mailvibesApi
      .delete(`/v2.1/distribution/${id}`)
      .then((response) => response.data)
      .catch((error) => error);
  }
);

export const createDistributionList = createAsyncThunk(
  "distributionLists/createDistributionList",
  async (distributionList) => {
    return mailvibesApi
      .post("/v2.1/distribution", distributionList)
      .then((response) => response.data);
  }
);

const templateListSlice = createSlice({
  name: "distributionLists",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDistributionList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDistributionList.fulfilled, (state, action) => {
      state.loading = false;
      state.distributions = action.payload.distributions;
      state.pages = action.payload.pages;
      state.count = action.payload.count;
    });
    builder.addCase(getDistributionList.rejected, (state, action) => {
      state.loading = false;
      state.templates = [];
      state.error = action.error.message;
    });
    builder.addCase(deleteDistribution.pending, (state, action) => {
      state.loading = true;
      state.distributions = state.distributions.filter(
        (dist) => dist.id !== action.meta.arg
      );
      state.error = "";
    });
    builder.addCase(deleteDistribution.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(deleteDistribution.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(createDistributionList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createDistributionList.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(createDistributionList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default templateListSlice.reducer;
