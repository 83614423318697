import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { mailvibesApi } from "../../api";

const initialState = {
  loading: false,
  mails: [],
  pages: 1,
  count: 20,
  error: "",
};

export const getEmailTracking = createAsyncThunk(
  "emailTracking/getEmailTracking",
  async ({
    status,
    search,
    date,
    rowsPerPage = 20,
    page = 1,
    order = "recipients",
    sort = "ASC",
  }) => {
    return mailvibesApi
      .get(
        `/v2.1/mail?limit=${rowsPerPage}&page=${page}&mindate=${
          date[0] === undefined ? "" : date[0]
        }&maxdate=${date[1] === undefined ? "" : date[1]}&search=${
          search ? search : ""
        }&status=${status ? status : "all"}&order=${order}&sort=${sort}`
      )
      .then((response) => {
        return response.data;
      });
  }
);

const emailTrackingSlice = createSlice({
  name: "emailTracking",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getEmailTracking.pending, (state) => {
      state.loading = true;
      state.mails = [];
      state.error = "";
    });

    builder.addCase(getEmailTracking.fulfilled, (state, action) => {
      state.loading = false;
      state.mails = action.payload.mails;
      state.pages = action.payload.pages;
      state.count = action.payload.count;
      state.error = "";
    });
    builder.addCase(getEmailTracking.rejected, (state, action) => {
      state.loading = false;
      state.mails = [];
      state.error = action.error.message;
    });
  },
});

export default emailTrackingSlice.reducer;
