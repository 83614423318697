import {
  Avatar as MaterialAvatar,
  AvatarGroup,
  Box,
  Tooltip,
} from "@mui/material";
import { theme } from "../theme";

export const Avatar = (props) => {
  const { img, letters, multiples, spacing, max, total, sx } = props;
  return (
    <Box sx={sx}>
      {img ? (
        multiples ? (
          <AvatarGroup
            spacing={spacing}
            max={max}
            total={total}
            sx={{
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                fontSize: 14,
                fontWeight: "500",
              },
            }}
          >
            {img?.map((image, index) => (
              <Tooltip title={image.name} key={index}>
                <MaterialAvatar alt={image.name} src={image.src} />
              </Tooltip>
            ))}
          </AvatarGroup>
        ) : (
          <MaterialAvatar alt="Remy Sharp" src={img} />
        )
      ) : multiples ? (
        <AvatarGroup
          spacing={spacing}
          max={max}
          total={total}
          sx={{
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              fontSize: 14,
              fontWeight: "500",
            },
          }}
        >
          {letters?.map((letter, index) => (
            <Tooltip title={letter.name} key={index}>
              <MaterialAvatar
                sx={{
                  bgcolor: theme.palette.primary.secondary,
                  width: 32,
                  height: 32,
                  fontSize: 14,
                  fontWeight: "500",
                }}
              >
                {letter.letters}
              </MaterialAvatar>
            </Tooltip>
          ))}
        </AvatarGroup>
      ) : (
        <MaterialAvatar
          sx={{
            bgcolor: theme.palette.primary.secondary,
            width: 32,
            height: 32,
            fontSize: 14,
            fontWeight: "500",
          }}
        >
          {letters}
        </MaterialAvatar>
      )}
    </Box>
  );
};
