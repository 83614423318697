const distributionTableHead = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "size",
    numeric: true,
    disablePadding: false,
    label: "Size",
  },
  {
    id: "lastUpdate",
    numeric: false,
    disablePadding: false,
    label: "Last update",
  },
  {
    id: "creator",
    numeric: false,
    disablePadding: false,
    label: "Creator",
  },
  {
    id: "owner",
    numeric: false,
    disablePadding: false,
    label: "Owner",
  },
  {
    id: "creationdate",
    numeric: false,
    disablePadding: false,
    label: "Creation date",
  },
];

const distributionRowsExample = [
  createData("Cupcake", "Cupcake", "Cupcake", "Cupcake", "Cupcake", "Cupcake"),
  createData("Donut", "Donut", "Donut", "Donut", "Donut", "Cupcake"),
  createData("Eclair", "Eclair", "Eclair", "Eclair", "Eclair", "Cupcake"),
  createData(
    "Frozen yoghurt",
    "Frozen yoghurt",
    "Frozen yoghurt",
    "Frozen yoghurt",
    "Frozen yoghurt",
    "Cupcake"
  ),
  createData(
    "Gingerbread",
    "Gingerbread",
    "Gingerbread",
    "Gingerbread",
    "Gingerbread",
    "Cupcake"
  ),
  createData(
    "Honeycomb",
    "Honeycomb",
    "Honeycomb",
    "Honeycomb",
    "Honeycomb",
    "Cupcake"
  ),
  createData(
    "Ice cream sandwich",
    "Ice cream sandwich",
    "Ice cream sandwich",
    "Ice cream sandwich",
    "Ice cream sandwich",
    "Cupcake"
  ),
  createData(
    "Jelly Bean",
    "Ice cream sandwich",
    "Ice cream sandwich",
    "Ice cream sandwich",
    "Ice cream sandwich",
    "Cupcake"
  ),
  createData(
    "KitKat",
    "Ice cream sandwich",
    "Ice cream sandwich",
    "Ice cream sandwich",
    "Ice cream sandwich",
    "Cupcake"
  ),
  createData(
    "Lollipop",
    "Ice cream sandwich",
    "Ice cream sandwich",
    98,
    0.0,
    "Cupcake"
  ),
  createData("Marshmallow", 318, 0, 81, 2.0, "Cupcake"),
  createData("Nougat", 360, 19.0, 9, 37.0, "Cupcake"),
  createData("Oreo", 437, 18.0, 63, 4.0, "Cupcake"),
];

function createData(name, subject, short_code, type, owner, lastUpdate) {
  return {
    name,
    subject,
    short_code,
    type,
    owner,
    lastUpdate,
  };
}

export { distributionTableHead, distributionRowsExample };
