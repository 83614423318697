import { forwardRef, useId } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Slide from "@mui/material/Slide";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Modal = (props) => {
  const { children, title, agree, disagree, open, setOpen, handleAction } =
    props;
  const titleId = useId();
  const contentId = useId();

  const handleClose = () => {
    setOpen(false);
  };

  const handleAgree = () => {
    setOpen(false);
    handleAction();
  };

  return (
    <div>
      <Dialog
        TransitionComponent={Transition}
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby={titleId}
        aria-describedby={contentId}
      >
        <DialogTitle id={titleId}>{title}</DialogTitle>
        <DialogContent sx={{ padding: 2 }}>
          <DialogContentText id={contentId} component="div">
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ paddingBottom: 2, paddingRight: 2 }}>
          <Button onClick={handleClose}>{disagree}</Button>
          <Button onClick={handleAgree} variant="contained">
            {agree}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
