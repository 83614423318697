import descendingComparator from "./descendingComparator";

/**
 * sort an array
 * @param {string} order
 * @param {string} orderBy
 * @returns {number}
 */
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default getComparator;
