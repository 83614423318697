import { CircularProgress, Box } from "@mui/material";

export const Spinner = ({ center, paddingTop }) => {
  return (
    <Box
      sx={{
        display: center && "flex",
        justifyContent: center && "center",
        alignItems: center && "center",
        paddingTop: paddingTop,
      }}
    >
      <CircularProgress />
    </Box>
  );
};
