import { Box, Typography, Button } from "@mui/material";
import { Avatar } from "./";
import LaptopChromebookIcon from "@mui/icons-material/LaptopChromebook";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { theme } from "../theme";
import { getMailLetter } from "../utils";
import { parseDate } from "../utils";

export const RecipientLabel = (props) => {
  const { receivers, createdAt, onClick, isScheduled } = props;
  const parseDateCreatedAt = parseDate(createdAt);
  const newReceivers = getMailLetter(receivers);

  const handleClick = (e) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "6%" }}>
        <Avatar
          letters={newReceivers}
          multiples={newReceivers?.length > 0}
          spacing={15}
          max={3}
          total={receivers?.length}
          sx={{ marginRight: "auto", width: "fit-content" }}
        />
      </Box>
      <Box
        sx={{
          width: "80%",
          marginLeft: 7,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        {receivers?.length > 1 ? (
          <Button
            onClick={handleClick}
            variant="text"
            sx={{
              width: "fit-content",
              color: theme.palette.mailvibes.secondary,
              textTransform: "capitalize",
              padding: "0px",
              "&:hover": {
                background: "none",
                color: theme.palette.mailvibes.primary,
              },
            }}
          >
            View All
          </Button>
        ) : (
          <Typography
            variant="subtitle2"
            sx={{ color: theme.palette.grays.dark200 }}
          >
            qwdq
          </Typography>
        )}
        <Typography
          variant="subtitle2"
          sx={{
            color: theme.palette.grays.dark40,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {isScheduled ? (
            <AccessTimeIcon
              sx={{
                width: 17,
                height: 17,
                color: theme.palette.grays.dark40,
                marginRight: "2px",
              }}
            />
          ) : (
            <LaptopChromebookIcon
              sx={{
                width: 17,
                height: 17,
                color: theme.palette.grays.dark40,
                marginRight: "2px",
              }}
            />
          )}

          {parseDateCreatedAt}
        </Typography>
      </Box>
    </Box>
  );
};
