import SuccessSvg from "../../assets/success.svg";

/**
 * Success icon
 * @returns {JSX.Element}
 */
const SuccessIcon = () => {
  return <img src={SuccessSvg} alt="Success" />;
};

export default SuccessIcon;
