import React from "react";
import { SuspenseWrapper } from "../containers";
const Sequence = React.lazy(() => import("../pages/Sequence"));
const SentSequence = React.lazy(() => import("../pages/Sequence/SentSequence"));
const DistributionLists = React.lazy(() =>
  import("../pages/MassEmail/DistributionLists/DistributionListsPage")
);
const Templates = React.lazy(() =>
  import("../pages/Sequence/Templates/TemplatesPage")
);
const Distribution = React.lazy(() =>
  import("../pages/MassEmail/Distribution/DistributionPage")
);
const EditTemplate = React.lazy(() =>
  import("../pages/Sequence/EditTemplate/EditTemplatePage")
);

const NotFound = React.lazy(() => import("../pages/NotFound"));

const sequenceRouter = [
  {
    path: "",
    element: (
      <SuspenseWrapper>
        <Sequence />
      </SuspenseWrapper>
    ),
  },
  {
    path: "sent-sequence",
    element: (
      <SuspenseWrapper>
        <SentSequence />,
      </SuspenseWrapper>
    ),
  },
  {
    path: "distribution",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <DistributionLists />
          </SuspenseWrapper>
        ),
      },
      {
        path: "edit/:id",
        element: (
          <SuspenseWrapper>
            <Distribution />
          </SuspenseWrapper>
        ),
      },
    ],
    errorElement: (
      <SuspenseWrapper>
        <NotFound />
      </SuspenseWrapper>
    ),
  },
  {
    path: "templates",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <Templates />
          </SuspenseWrapper>
        ),
      },
      {
        path: "edit/:id",
        element: (
          <SuspenseWrapper>
            <EditTemplate />
          </SuspenseWrapper>
        ),
      },
      {
        path: "new",
        element: (
          <SuspenseWrapper>
            <EditTemplate />
          </SuspenseWrapper>
        ),
      },
    ],
    errorElement: (
      <SuspenseWrapper>
        <NotFound />
      </SuspenseWrapper>
    ),
  },
];

export default sequenceRouter;
