import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { parseDate } from "../utils";

import "react-datepicker/dist/react-datepicker.css";
import "../styles/muxDateRangePicker.css";

/**
 *  Date range picker
 * @param {object} props
 * @param {function} onChange
 * @returns {JSX.Element}
 */
export const MUXDateRangePicker = ({ onChange, reset }) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    setDateRange([null, null]);
  }, [reset]);

  const handleChange = (date) => {
    const [newStartDate, newEndDate] = date;
    setDateRange(() => {
      let parseNewDate = [];
      let newDate = [];
      if (newStartDate) {
        const parseStartDate = parseDate(newStartDate);
        parseNewDate = [parseStartDate, date[1]];
        newDate = [newStartDate, date[1]];
      }

      if (newEndDate) {
        const parseEndDate = parseDate(newEndDate);
        parseNewDate = [parseNewDate[0], parseEndDate];
        newDate = [newDate[0], newEndDate];
      }

      onChange(parseNewDate);
      return newDate;
    });
  };

  return (
    <div>
      <DatePicker
        className="mux-date-range-picker"
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          handleChange(update);
        }}
        withPortal
        dateFormat="MM dd, yyyy"
      />
    </div>
  );
};

export default MUXDateRangePicker;
