import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  chat: [],
  chatAi: [],
  error: "",
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    addMessage: (state, action) => {
      state.error = "";
      state.loading = false;
      state.chat = [...state.chat, action.payload];
      state.chatAi = [
        ...state.chatAi,
        { role: action.payload.role, content: action.payload.message },
      ];
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
      state.error = "";
    },
  },
});

export default chatSlice.reducer;
export const { setError, addMessage, setLoading } = chatSlice.actions;
