import VisibilityIcon from "@mui/icons-material/Visibility";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { theme } from "../theme/index";

/**
 * get view icons
 * @param {number} total
 * @param {number} n
 * @returns {JSX.Element}
 */
export default function getViewIcon(total, n) {
  let icon = "";
  const style = {
    position: "relative",
    top: "8px",
    marginRight: "5px",
    color: theme.palette.primary.main,
  };

  if (total > 0) {
    const percentage = Math.trunc((n / total) * 100);
    if (percentage >= 30) {
      icon = <WhatshotIcon sx={style} />;
    } else if (percentage >= 20) {
      icon = <ThumbUpIcon sx={style} />;
    } else if (percentage >= 10) {
      icon = <VisibilityIcon sx={style} />;
    }
  }

  return icon;
}
