import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { mailvibesApi } from "../../api";

const initialState = {
  loading: false,
  template: "",
  subject: "",
  error: "",
};

export const createComposeTemplate = createAsyncThunk(
  "composeAI/createComposeTemplate",
  async (data) => {
    return mailvibesApi
      .post("/v2/integration/openai/template", data)
      .then((response) => {
        return response.data;
      });
  }
);

export const sendComposeMail = createAsyncThunk(
  "composeAI/sendComposeMail",
  async (data) => {
    return mailvibesApi
      .post("/v2/integration/openai/send", data)
      .then((response) => {
        return response.data;
      });
  }
);

const summarizerSlice = createSlice({
  name: "composeAI",
  initialState,
  reducers: {
    clean: (state) => {
      state.subject = "";
      state.template = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createComposeTemplate.pending, (state) => {
      state.loading = true;
      state.subject = "";
      state.template = "";
      state.error = "";
    });
    builder.addCase(createComposeTemplate.fulfilled, (state, action) => {
      state.loading = false;
      state.template =
        action.payload.template.content || action.payload.template;
      state.subject =
        action.payload.template?.subject ||
        action.payload.template.split(" ").slice(0, 10).join(" ");
      state.error = "";
    });
    builder.addCase(createComposeTemplate.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.error?.message;
    });
    builder.addCase(sendComposeMail.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(sendComposeMail.fulfilled, (state, action) => {
      state.loading = false;
      state.template = "";
      state.subject = "";
      state.error = "";
    });
    builder.addCase(sendComposeMail.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.error?.message;
    });
  },
});

export default summarizerSlice.reducer;
export const { clean } = summarizerSlice.actions;
