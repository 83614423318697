import { useDispatch, useSelector } from "react-redux";
import { signin } from "../redux/features/authSlice";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { Backdrop, CircularProgress } from "@mui/material";
import { snackbar } from "../utils";

/**
 * it show the google signin button
 * and handle the signin function
 * @returns {JSX.Element}
 */
export const GoogleSigninButton = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const handleOnSuccess = async (response) => {
    try {
      const { credential } = response;
      dispatch(signin(credential));
    } catch (error) {
      snackbar(`error: ${error?.message}`, "error");
    }
  };

  const handleOnFailure = (error) => {
    snackbar(`error: ${error?.message}`, "error");
  };

  return (
    <>
      <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT}`}>
        <GoogleLogin
          state={234}
          render={(renderProps) => (
            <button
              type="button"
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              Sign in with google
            </button>
          )}
          onSuccess={handleOnSuccess}
          onFailure={handleOnFailure}
          cookiePolicy="single_host_origin"
        />
      </GoogleOAuthProvider>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={auth.loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
