import { Navigate } from "react-router-dom";
import { useAuth } from "../../hooks";

/**
 * Protects routes from unidentified users
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @returns {JSX.Element}
 */
const ProtectedRoute = ({ children }) => {
  const isAuth = useAuth();
  return <>{isAuth ? children : <Navigate to="/signin" />}</>;
};

export default ProtectedRoute;
