import React from "react";

import { SuspenseWrapper } from "../containers";

const EmailTracking = React.lazy(() =>
  import("../pages/EmailTracking/Tracking/EmailTrackingPage")
);
const LinkTrackingStats = React.lazy(() =>
  import("../pages/EmailTracking/LinkTrackingStats/LinkTrackingStatsPage")
);

const emailTrackingRouter = [
  {
    path: "",
    element: (
      <SuspenseWrapper>
        <EmailTracking />
      </SuspenseWrapper>
    ),
  },
  {
    path: "link-tracking-stats",
    element: (
      <SuspenseWrapper>
        <LinkTrackingStats />
      </SuspenseWrapper>
    ),
  },
];

export default emailTrackingRouter;
