import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { MainLayout } from "../layouts/index";
import emailTrackingRouter from "./emailTrackingRouter";
import massEmailRouter from "./massEmailRouter";
import sequenceRouter from "./sequenceRouter";
import mailvibesSettingsRouter from "./mailvibesSettingsRouter";
import integrationsRouter from "./integrationsRouter";

import ProtectedRoutes from "./authRoutes/ProtectedRoutes";
import PublicRoutes from "../routes/authRoutes/PublicRoutes";
import { SuspenseWrapper } from "../containers";

const NotFound = React.lazy(() => import("../pages/NotFound"));
const Signin = React.lazy(() => import("../pages/Signin"));
const Home = React.lazy(() => import("../pages/Home"));

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <SuspenseWrapper>
        <ProtectedRoutes>
          <MainLayout />
        </ProtectedRoutes>
      </SuspenseWrapper>
    ),
    errorElement: (
      <SuspenseWrapper>
        <NotFound />
      </SuspenseWrapper>
    ),
    children: [
      {
        path: "",
        element: (
          <Home>
            <Home />
          </Home>
        ),
      },
      {
        path: "email-tracking",
        children: emailTrackingRouter,
        errorElement: (
          <SuspenseWrapper>
            <NotFound />
          </SuspenseWrapper>
        ),
      },
      {
        path: "mass-email",
        children: massEmailRouter,
        errorElement: (
          <SuspenseWrapper>
            <NotFound />
          </SuspenseWrapper>
        ),
      },
      {
        path: "sequence",
        children: sequenceRouter,
        errorElement: (
          <SuspenseWrapper>
            <NotFound />
          </SuspenseWrapper>
        ),
      },
      {
        path: "mailvibes-settings",
        children: mailvibesSettingsRouter,
        errorElement: (
          <SuspenseWrapper>
            <NotFound />
          </SuspenseWrapper>
        ),
      },
      {
        path: "integrations",
        children: integrationsRouter,
        errorElement: (
          <SuspenseWrapper>
            <NotFound />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "/signin",
    element: (
      <PublicRoutes>
        <SuspenseWrapper>
          <Signin />
        </SuspenseWrapper>
      </PublicRoutes>
    ),
    errorElement: <NotFound />,
  },
]);

export default router;
