import ScheduledSvg from "../../assets/scheduled.svg";

/**
 * Scheduled icon
 * @returns {JSX.Element}
 */
const ScheduledIcon = () => {
  return <img src={ScheduledSvg} alt="scheduled" />;
};

export default ScheduledIcon;
