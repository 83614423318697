import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { mailvibesApi } from "../../../api";
import { fetchTemplate } from "../../../redux/features/TemplateSlice";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import TableCellWrapper from "../TableCellWrapper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CollapseWrapper from "../CollapseWrapper";
import { theme } from "../../../theme/index";
import { hasTemplate } from "../../../utils/sentMails/hasTemplate";
import { snackbar } from "../../../utils";

/**
 * show a row form data table
 * @param {object} props
 * @param {object} props.row
 * @returns {JSX.Element}
 */
const Row = ({ row, isItemSelected, labelId, handleClick }) => {
  const sentMails = useSelector((state) => state.sentMails);
  const [recipients, setRecipients] = useState([]);
  const [loadingRecipients, setLoadingRecipients] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const isTemplate = hasTemplate(row);

  useEffect(() => {
    if (!isOpen) return;
    setLoadingRecipients(true);
    mailvibesApi
      .get(`/v2/mail/mass/${row.id}/recipients`)
      .then((response) => {
        setRecipients(response?.data);
      })
      .catch((error) => snackbar(`error: ${error?.message}`, "error"))
      .finally(() => setLoadingRecipients(false));
  }, [isOpen, row?.id]);

  const handleOnClick = (event) => {
    handleClick(event, row.id);
    setIsOpen((prev) => !prev);
  };

  const handleTemplate = async () => {
    if (isTemplate) {
      const templateId = row.MassMailRecipients[0].Mail.template_id;
      dispatch(fetchTemplate(templateId));
    }
  };

  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        selected={isItemSelected}
        sx={{ "&:hover": { cursor: "pointer" } }}
      >
        <TableCell></TableCell>
        <TableCellWrapper
          onClick={!isTemplate ? handleOnClick : () => {}}
          component="th"
          id={labelId}
          scope="row"
          loading={sentMails.loading}
          sx={{
            position: "relative",

            color: theme.palette.mailvibes.secondary,
          }}
        >
          <>
            <Tooltip
              title={`This email ${
                isTemplate ? "use" : "doesn't use"
              } template`}
              placement="top-start"
            >
              <VisibilityIcon
                onClick={handleTemplate}
                sx={{
                  position: "absolute",
                  top: "14px",
                  left: "-14px",
                  marginRight: "5px",
                  color: row.hasTemplate
                    ? theme.palette.grays.primary
                    : theme.palette.grays.secondary,
                }}
              />
            </Tooltip>

            {row.subject}
          </>
        </TableCellWrapper>
        <TableCellWrapper
          loading={sentMails.loading}
          align="left"
          onClick={handleOnClick}
        >
          {row.sentAs}
        </TableCellWrapper>
        <TableCellWrapper
          loading={sentMails.loading}
          align="center"
          onClick={handleOnClick}
        >
          {row.sent === 0 ? "N/A" : row.sent}
        </TableCellWrapper>
        <TableCellWrapper
          loading={sentMails.loading}
          align="center"
          onClick={handleOnClick}
        >
          {row.notSent}
        </TableCellWrapper>
        <TableCellWrapper
          loading={sentMails.loading}
          align="center"
          onClick={handleOnClick}
        >
          <>
            {row.icon}

            {row.viewed}
          </>
        </TableCellWrapper>
        <TableCellWrapper
          loading={sentMails.loading}
          align="center"
          onClick={handleOnClick}
        >
          {row.unsubscribed}
        </TableCellWrapper>
        <TableCellWrapper
          loading={sentMails.loading}
          align="center"
          onClick={handleOnClick}
        >
          {row.bounced}
        </TableCellWrapper>
        <TableCellWrapper loading={sentMails.loading} align="center">
          {row.createdAt}
        </TableCellWrapper>
      </TableRow>

      <TableRow sx={{ border: "none" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <CollapseWrapper
            isOpen={isOpen}
            rows={recipients}
            loading={loadingRecipients}
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default Row;
