import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

export const UploadFile = ({ register, error }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      sx={{ pb: "1.4rem" }}
    >
      <Button
        variant="outlined"
        component="label"
        sx={{ cursor: "pointer", width: "100%" }}
        startIcon={<CloudUploadIcon />}
      >
        Click or Drop files to upload
        <input
          hidden
          accept="audio/*,video/*"
          multiple
          type="file"
          {...register("file", { required: true })}
        />
      </Button>
      <Typography variant="subtitle1" color="gray" sx={{ fontSize: "12px" }}>
        File must be a video or audio with 25 mb max
      </Typography>
      {error && (
        <Alert variant="outlined" severity="error">
          Must upload a file
        </Alert>
      )}
    </Box>
  );
};
