import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { mailvibesApi } from "../../api";

const initialState = {
  loading: false,
  rows: [],
  columns: [],
  error: "",
};

export const getDistribution = createAsyncThunk(
  "distribution/getDistribution",
  async (id) => {
    return mailvibesApi
      .get(`/v2.1/distribution/${id}`)
      .then((response) => response.data)
      .catch((error) => error.message);
  }
);

const distributionSlice = createSlice({
  name: "distributionSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDistribution.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDistribution.fulfilled, (state, action) => {
      state.loading = false;
      state.columns = action.payload.columns;
      state.rows = action.payload.rows;
    });
    builder.addCase(getDistribution.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default distributionSlice.reducer;
