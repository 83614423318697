export const settings = [
  {
    icon: "statistics",
    title: "Statistics",
    desc: "Receive a weekly report",
    short_desc: "Report",
    active: true,
    enabled: true,
    items: [
      {
        icon: "report",
        title: "Weekly report",
        endpoint: "settings",
        option: "weekly_report",
        enabled: true,
      },
    ],
  },
  {
    icon: "link_tracking",
    title: "Link tracking",
    desc: "Notifications you get when someone opens your tracked link",
    short_desc: "Push, email",
    active: true,
    enabled: true,
    items: [
      {
        icon: "push",
        title: "Real time",
        option: "link_notify_push",
        enabled: true,
      },
      {
        icon: "email",
        title: "Email",
        option: "link_notify_email",
        enabled: true,
      },
      /* {
          icon: "sms",
          title: "settings_sms_title",
          option: "link_notify_sms",
          locked: isFreeUser(),
        }, */
    ],
  },
  {
    icon: "seen",
    title: "Email tracking",
    desc: "Notifications you receive when someone opens your tracked email",
    short_desc: "Push, email",
    option: "enabled",
    items_desc: "where_get_notifications",

    active: true,
    enabled: true,
    items: [
      {
        icon: "push",
        title: "Real time",
        option: "notify_push",
        endpoint: "settings",
        enabled: true,
      },
      {
        icon: "email",
        title: "Email",
        option: "notify_email",
        endpoint: "settings",
        enabled: true,
      },
      /* {
          icon: "sms",
          title: "settings_sms_title",
          option: "notify_sms",
          locked: isFreeUser(),
        }, */
    ],
  },
  {
    icon: "seen",
    title: "Reply email tracking",
    desc: "Notifications you receive when someone opens your tracked reply email",
    short_desc: "Push, email",
    option: "reply_enabled",

    items_desc: "where_get_notifications",
    active: true,
    enabled: true,
    items: [
      {
        icon: "push",
        title: "Real time",
        option: "reply_notify_push",

        enabled: true,
      },
      {
        icon: "email",
        title: "Email",
        option: "reply_notify_email",

        enabled: true,
      },
      /* {
          icon: "sms",
          title: "settings_sms_title",
          option: "reply_notify_sms",
          locked: isFreeUser(),
        }, */
    ],
  },
  {
    icon: "mass_mailing",
    title: "Mass mailing",
    desc: "Send mass emails with our services",
    short_desc: "Send more than 300 emails a day",
    active: true,
    enabled: true,
    items: [
      {
        icon: "unsubscribe",
        title: "Unsubscribe link",
        option: "unsubscribe_link",
        enabled: true,
      },
    ],
  },
  {
    icon: "tracker_shield",
    title: "Trackers blocking",
    desc: "Block tracking from other tools so they can't know when you open their emails",
    short_desc: "Do not allow tracking",
    active: true,
    enabled: true,
    items: [
      {
        icon: "padlock",
        title: "Blocking",
        option: "trackers_blocking",

        locked: true,
        enabled: true,
      },
    ],
  },
];
