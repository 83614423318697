import { useCallback } from "react";
import debounce from "lodash.debounce";

/**
 * it will apply debounce to the value
 * @param {string} value
 * @returns {string}
 */
export default function useDebounce({ setState, prop, timeout = 1000 }) {
  // eslint-disable-next-line react-hooks/exhaustive-deps

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSaved = useCallback(
    debounce(
      (nextValue) =>
        setState((prev) =>
          typeof prev === "object" ? { ...prev, [prop]: nextValue } : nextValue
        ),
      timeout
    ),
    []
  );

  return debouncedSaved;
}
