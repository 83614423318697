import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { mailvibesApi } from "../../api";

const initialState = {
  loading: false,
  links: [],
  pages: 1,
  count: 20,
  error: "",
};

export const getLinksTracking = createAsyncThunk(
  "emailTracking/getEmailTracking",
  async ({
    status,
    search,
    date,
    rowsPerPage = 20,
    page = 1,
    order = "subject",
    sort = "ASC",
  }) => {
    return mailvibesApi
      .get(
        `/v2.1/link?limit=${rowsPerPage}&page=${page}&mindate=${
          date[0] === undefined ? "" : date[0]
        }&maxdate=${date[1] === undefined ? "" : date[1]}&search=${
          search ? search : ""
        }&status=${status ? status : "all"}&order=${order}&sort=${sort}`
      )
      .then((response) => {
        return response.data;
      });
  }
);

const linkTrackingSlice = createSlice({
  name: "linkTracking",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getLinksTracking.pending, (state) => {
      state.loading = true;
      state.links = [];
      state.error = "";
    });

    builder.addCase(getLinksTracking.fulfilled, (state, action) => {
      state.loading = false;
      state.links = action.payload.links;
      state.pages = action.payload.pages;
      state.count = action.payload.count;
      state.error = "";
    });
    builder.addCase(getLinksTracking.rejected, (state, action) => {
      state.loading = false;
      state.links = [];
      state.error = action.error.message;
    });
  },
});

export default linkTrackingSlice.reducer;
