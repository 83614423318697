import { useSelector } from "react-redux";

/***
 * if user is auth true|false
 * @return {boolean}
 */
function useAuth() {
  const auth = useSelector((state) => state.auth);

  return auth?.user?.access_token;
}

export default useAuth;
