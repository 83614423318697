/**
 * Crea data de ejemplo
 * @param {Number} id
 * @param {String} recipients
 * @param {String} subject
 * @param {String} opened
 * @param {Boolean} mute
 * @param {String} notes
 * @param {String} createdAt
 * @returns
 */
function createData(id, recipients, subject, opened, mute, notes, createdAt) {
  return {
    id,
    recipients,
    subject,
    opened,
    mute,
    notes,
    createdAt,
    receivers: [
      {
        date: "2020-01-05",
        email: "example@gmail.com",
        amount: 3,
      },
      {
        date: "2020-01-02",
        email: "example@gmail.com",
        amount: 1,
      },
    ],
  };
}

const emailTrackingStatsExampleData = [
  createData(
    1,
    "Frozen yoghurt",
    159,
    6.0,
    24,
    4.0,
    "2023-03-21T18:39:08.604Z"
  ),
  createData(
    2,
    "Ice cream sandwich",
    237,
    9.0,
    37,
    4.3,
    "2023-03-21T18:39:08.604Z"
  ),
  createData(3, "Eclair", 262, 16.0, 24, 6.0, "2023-03-21T18:39:08.604Z"),
  createData(4, "Cupcake", 305, 3.7, 67, 4.3, "2023-03-21T18:39:08.604Z"),
  createData(5, "Gingerbread", 356, 16.0, 49, 3.9, "2023-03-21T18:39:08.604Z"),
  createData(6, "Gingerbread", 356, 16.0, 49, 3.9, "2023-03-21T18:39:08.604Z"),
  createData(7, "Gingerbread", 356, 16.0, 49, 3.9, "2023-03-21T18:39:08.604Z"),
  createData(8, "Gingerbread", 356, 16.0, 49, 3.9, "2023-03-21T18:39:08.604Z"),
  createData(9, "Gingerbread", 356, 16.0, 49, 3.9, "2023-03-21T18:39:08.604Z"),
  createData(10, "Gingerbread", 356, 16.0, 49, 3.9, "2023-03-21T18:39:08.604Z"),
];

const emailTrackingStatsHeadCells = [
  {
    id: "recipients",
    numeric: false,
    disablePadding: false,
    label: "Recipients",
  },
  {
    id: "subject",
    numeric: false,
    disablePadding: false,
    label: "Subject",
  },
  {
    id: "sentAs",
    numeric: true,
    disablePadding: false,
    label: "Sent as",
  },
  {
    id: "opened",
    numeric: true,
    disablePadding: false,
    label: "Opened",
  },
  {
    id: "mute",
    numeric: false,
    disablePadding: false,
    label: "Mute",
  },
  {
    id: "notes",
    numeric: false,
    disablePadding: false,
    label: "Notes",
  },
];

export default emailTrackingStatsExampleData;
export { emailTrackingStatsHeadCells };
