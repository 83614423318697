import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { ProfileMenu, MenuOptions } from "../../../components";
import MailvibesLogo from "../../../assets/mailvibes-logo.svg";
import { menuLinks } from "../../../constants";

/**
 * it shows a navbar
 * @returns {JSX.Element}
 */
function NavBar() {
  return (
    <AppBar
      position="fixed"
      sx={{
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        backgroundColor: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      elevation={0}
    >
      <Container maxWidth="false" sx={{ padding: "0 30px !important" }}>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}>
            <img src={MailvibesLogo} alt="mailvibes" />
          </Box>
          <MenuOptions links={menuLinks} />
          <ProfileMenu />
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBar;
