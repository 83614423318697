import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { mailvibesApi } from "../../api";

const initialState = {
  settings: {
    loading: false,
    birth_date: undefined,
    createdAt: undefined,
    email: "",
    id: "",
    last_name: "",
    name: "",
    phone: "",
    primary_user_id: undefined,
    registration_id: "",
    status: 0,
    stripe_customer_id: "",
    updatedAt: undefined,
    lang: "",
    country: "",
    timezone: "",
    current_plan: "",
    start_date: undefined,
    valid_until: undefined,
    MemberSubscriptions: [],
    closedAcountGroups: "",
    openai_key: "",
  },
  loading: false,
  error: "",
  message: "",
};

export const fetchSettings = createAsyncThunk(
  "account/fetchSettings",
  async () =>
    mailvibesApi.get("/v2/user").then((response) => {
      return response.data;
    })
);

export const saveSettings = createAsyncThunk(
  "account/saveSettings",
  async (data) => mailvibesApi.put("/v2/user/general", data)
);

export const saveLang = createAsyncThunk("acount/saveLang", async (data) =>
  mailvibesApi.put("/v2/user/setting", data)
);
const accountSlice = createSlice({
  name: "account",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchSettings.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSettings.fulfilled, (state, action) => {
      state.settings.name = action.payload?.name;
      state.settings.last_name = action.payload?.last_name;
      state.settings.email = action.payload?.email;
      state.settings.phone = action.payload?.phone;
      state.settings.createdAt = action.payload?.createdAt;

      state.settings.birth_date = action.payload?.birth_date;
      state.settings.lang = action.payload?.settings?.lang;
      state.settings.timezone = action.payload?.settings?.timezone;
      state.settings.country = action.payload?.settings?.country;
      state.settings.current_plan =
        action.payload?.Subscriptions[0]?.Pricing.name;
      state.settings.start_date = action.payload.Subscriptions[0]?.start_date;
      state.settings.valid_until = action.payload.Subscriptions[0]?.end_date;
      state.settings.status = action.payload.Subscriptions[0]?.status;
      state.settings.MemberSubscriptions = action.payload.MemberSubscriptions;
      state.settings.stripe_customer_id = action.payload.stripe_customer_id;
      state.settings.closedAcountGroups =
        action.payload.settings.closedAcountGroups;
      state.settings.openai_key = action?.payload?.settings?.openai_key;
      state.loading = false;
    });
    builder.addCase(fetchSettings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(saveSettings.pending, (state, action) => {
      state.settings.loading = true;
      for (let option in action.meta.arg) {
        state.settings[option] = action.meta.arg[option];
      }
    });
    builder.addCase(saveSettings.fulfilled, (state) => {
      state.settings.loading = false;
    });
    builder.addCase(saveSettings.rejected, (state, action) => {
      state.settings.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(saveLang.pending, (state, action) => {
      const { name, value } = action.meta.arg;
      state.settings[name] = value;
    });

    builder.addCase(saveLang.rejected, (state, action) => {
      state.error = action.error.message;
    });
  },
});

export default accountSlice.reducer;
