export default function arrayHasProperty(array, property) {
  if (!Array.isArray(array)) {
    return;
  }
  if (typeof property !== "string") {
    return;
  }
  const hasProperty = array.every((obj) => obj.hasOwnProperty(property));
  return hasProperty;
}
