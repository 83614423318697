import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Tabs, Tab } from "@mui/material";
import { theme } from "../theme";
import getRoutePathname from "../utils/getRoutePathname";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/**
 *
 * it shows a list of tabs menu
 * @returns {JSX.Element}
 */
export const MenuTabs = ({ pages }) => {
  const location = useLocation();
  const currentPathname = getRoutePathname(location.pathname, 2);

  return (
    <>
      <Tabs
        color="mailvibes"
        TabIndicatorProps={{
          style: {
            background: theme.palette.mailvibes.main,
          },
        }}
        value={currentPathname}
        aria-label="section options"
      >
        {pages.map((item, index) => (
          <Tab
            component={Link}
            to={item?.link}
            value={getRoutePathname(item.link, 2)}
            key={index}
            label={item?.name}
            {...a11yProps(getRoutePathname(item.link, 2))}
          ></Tab>
        ))}
      </Tabs>
    </>
  );
};
