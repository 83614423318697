import React from "react";

import { SuspenseWrapper } from "../containers";

const ComposeAIPage = React.lazy(() =>
  import("../pages/integrations/ComposeAI/ComposeAIPage")
);
const Summarizer = React.lazy(() =>
  import("../pages/integrations/Summarizer/SummarizerPage")
);

const integrationsRouter = [
  {
    path: "",
    element: (
      <SuspenseWrapper>
        <ComposeAIPage />
      </SuspenseWrapper>
    ),
  },
  {
    path: "summarizer",
    element: (
      <SuspenseWrapper>
        <Summarizer />
      </SuspenseWrapper>
    ),
  },
];

export default integrationsRouter;
