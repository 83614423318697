import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { mailvibesApi } from "../../api";

const initialState = {
  options: {
    weekly_report: false,
    notify_email: false,
    notify_sms: false,
    notify_push: false,
    link_notify_email: false,
    link_notify_sms: false,
    link_notify_push: false,
    reply_notify_email: false,
    reply_notify_sms: false,
    reply_notify_push: false,
    enabled: false,
    reply_enabled: false,
    menu_icons: false,
    send_by_ses: false,
    trackers_blocking: false,
    unsubscribe_link: false,
    closedSettingsGroups: "",
  },
  loading: false,
  error: "",
};

export const fetchSettings = createAsyncThunk(
  "settings/fetchSettings",
  async () =>
    mailvibesApi.get("/v2/user").then((response) => response.data.settings)
);

export const saveSettings = createAsyncThunk(
  "settings/saveSettings",
  async (data) => {
    mailvibesApi.put("/v2/user/setting", data);
  }
);

export const saveSettingsGroup = createAsyncThunk(
  "settings/saveSettingsGroup",
  async (data) => {
    mailvibesApi.put("user", data);
    return data;
  }
);

const settingsSlice = createSlice({
  name: "settings",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(fetchSettings.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSettings.fulfilled, (state, action) => {
      state.options.weekly_report = action.payload?.weekly_report;
      state.options.notify_email = action.payload.notify_email;
      state.options.notify_sms = action.payload.notify_sms;
      state.options.notify_push = action.payload.notify_push;
      state.options.link_notify_email = action.payload.link_notify_email;
      state.options.link_notify_sms = action.payload.link_notify_sms;
      state.options.link_notify_push = action.payload.link_notify_push;
      state.options.reply_notify_email = action.payload.reply_notify_email;
      state.options.reply_notify_sms = action.payload.reply_notify_sms;
      state.options.reply_notify_push = action.payload.reply_notify_push;
      state.options.enabled = action.payload.enabled;
      state.options.reply_enabled = action.payload.reply_enabled;
      state.options.menu_icons = action.payload.menu_icons;
      state.options.send_by_ses = action.payload.send_by_ses;
      state.options.trackers_blocking = action.payload.trackers_blocking;
      state.options.unsubscribe_link = action.payload.unsubscribe_link;
      state.options.closedSettingsGroups = action.payload.closedSettingsGroups;
      state.loading = false;
    });
    builder.addCase(fetchSettings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(saveSettings.pending, (state, action) => {
      state.options[action.meta.arg.name] = action.meta.arg.value;
      state.loading = true;
    });
    builder.addCase(saveSettings.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(saveSettings.rejected, (state, action) => {
      state.error = action.error.message;
    });
    builder.addCase(saveSettingsGroup.pending, (state, action) => {
      for (let option in action.meta.arg.settings) {
        state.options[option] = action.meta.arg.settings[option];
      }
      state.loading = true;
    });
    builder.addCase(saveSettingsGroup.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(saveSettingsGroup.rejected, (state, action) => {
      state.error = action.error.message;
    });
  },
});

export default settingsSlice.reducer;
