/**
 * sort an array
 * @param {array} a
 * @param {array} b
 * @param {string} orderBy
 * @returns {number}
 */
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export default descendingComparator;
