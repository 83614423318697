import hasTemplate from "./hasTemplate";
import getViewIcon from "./getViewIcon";
import parseDateRelative from "./parseDateRelative";

/**
 * transform mails in proper mails list
 * for sent mails data table
 * @param {object} mails
 * @returns {array[object]}
 */
export default function getMails(mails) {
  let newMails = mails.map((item) => {
    let newMail = {
      ...item,
      sent: 0,
      notSent: 0,
      bounced: 0,
      unsubscribed: 0,
      viewed: 0,
      hasTemplate: hasTemplate(item),
      subject: item?.title,
      createdAt: parseDateRelative(
        item.ScheduledMail ? item.ScheduledMail.send_date : item.createdAt
      ),
    };

    if (item.MassMailRecipients.length && item.MassMailRecipients[0].Mail) {
      newMail.sentAs = item.MassMailRecipients[0].Mail.sent_as;
    }
    for (let recipient of item.MassMailRecipients) {
      newMail.sent += recipient.status === "success" ? 1 : 0;
      newMail.notSent += recipient.status === "rejected" ? 1 : 0;
      newMail.bounced += recipient.status === "bounced" ? 1 : 0;
      if (recipient.Mail) {
        newMail.viewed += recipient.Mail.views ? 1 : 0;
        if (
          recipient.Mail.receivers &&
          recipient.Mail.receivers.length &&
          recipient.Mail.receivers[0].UserMailList
        ) {
          newMail.unsubscribed +=
            recipient.Mail.receivers[0].UserMailList.email_status ===
            "unsubscribed"
              ? 1
              : 0;
        }
      }
    }

    newMail.icon = getViewIcon(
      newMail.MassMailRecipients.length,
      newMail?.viewed
    );
    return newMail;
  });
  return newMails;
}
