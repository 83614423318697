import { formatRelative, format } from "date-fns";
import { enUS } from "date-fns/locale";

/**
 * Transform dates
 * @param {string} dateString
 * @returns {string}
 */
export default function parseDateRelative(dateString) {
  return formatRelative(new Date(dateString), new Date());
}

function parseDate(date) {
  return format(new Date(date), "MMM d, yyyy '|' HH:mm a", { locale: enUS });
}

export { parseDate };
