export const account = [
  {
    key: "general",
    title: "General information",
    description:
      "Basic information such as name, company, and other contact information.",
    loading: false,
    icon: "general",
    index: "2",
    items: [
      {
        key: "email",
        label: "account",
        title: "Account",
        editable: false,
        value: "",
      },
      {
        key: "createdAt",
        label: "created_date",
        type: "date",
        title: "Created date",
        editable: false,
        value: "",
      },
      {
        key: "name",
        label: "account_option_name",
        title: "Name",
        editable: true,
        value: "",
        editing: false,
        handler: "saveGeneral",
        type: "text",
        loading: false,
      },
      {
        key: "last_name",
        label: "last_name",
        title: "Last name",
        editable: true,
        value: "",
        editing: false,
        handler: "saveGeneral",
        type: "text",
      },
      {
        key: "phone",
        label: "account_option_phone",
        title: "Phone",
        editable: true,
        value: "",
        editing: false,
        handler: "saveGeneral",
        type: "text",
        loading: false,
        maxlength: 15,
      },
      {
        key: "birth_date",
        label: "birthdate",
        title: "Birthday",
        editable: true,
        value: "",
        editing: false,
        handler: "saveGeneral",
        type: "date",
        loading: false,
      },
    ],
  },
  {
    key: "preferences",
    title: "Preferences",
    description: "Preferences to make MailVibes services more useful to you.",
    loading: false,
    icon: "preferences",
    index: "1",
    items: [
      {
        key: "lang",
        label: "account_option_language",
        title: "Language",
        editable: true,
        value: "",
        editing: false,
        handler: "saveSettings",
        type: "select",
        loading: false,
        options: [
          {
            name: "اللغة العربية",
            id: "ar",
          },
          {
            name: "বাংলা",
            id: "bn",
          },
          {
            name: "Deutsch",
            id: "de",
          },
          {
            name: "English",
            id: "en",
          },
          {
            name: "Español",
            id: "es",
          },
          {
            name: "Français",
            id: "fr",
          },
          {
            name: "हिंदी",
            id: "hi",
          },
          {
            name: "Italiano",
            id: "it",
          },
          {
            name: "日本語",
            id: "ja",
          },
          {
            name: "Bahasa melayu",
            id: "ms",
          },
          {
            name: "Português (Brasil)",
            id: "pt_BR",
          },
          {
            name: "Português (Portugal)",
            id: "pt_PT",
          },
          {
            name: "Pусский",
            id: "ru",
          },
          {
            name: "தமிழ்",
            id: "ta",
          },
          {
            name: "Türk",
            id: "tr",
          },
          {
            name: "中文 (中国)",
            id: "zh_CN",
          },
          {
            name: "中文 (台湾)",
            id: "zh_TW",
          },
        ],
      },
      {
        key: "openai_key",
        label: "openaikey",
        title: "Open AI api key",
        editable: true,
        value: "",
        editing: false,
        handler: "saveSettings",
        type: "password",
        loading: true,
      },
      {
        key: "timezone",
        label: "account_option_timezone",
        title: "Timezone",
        editable: true,
        value: "",
        editing: false,
        handler: "saveSettings",
        type: "select",
        loading: false,
        options: [],
      },
      {
        key: "country",
        label: "account_option_country",
        title: "Country",
        editable: false,
        value: "",
      },
    ],
  },
  {
    key: "plan",
    title: "Plan",
    description:
      "Update your information, review payment details, transactions, and recurring payments.",
    loading: false,
    icon: "plan",
    index: "0",
    items: [
      {
        key: "current_plan",
        label: "account_option_current_plan",
        title: "Current plan",
        editable: false,
        value: "",
        buttonShow: true,
        buttonLabel: "upgrade_now",
      },
      {
        key: "start_date",
        type: "text",
        label: "account_option_start_date",
        title: "Start date",
        editable: false,
        value: "",
      },
      {
        key: "valid_until",
        type: "text",
        label: "account_option_valid_until",
        title: "Valid until",
        editable: false,
        value: "",
      },
    ],
  },
];
