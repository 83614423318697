import { Button, Link, Box } from "@mui/material";
import { theme } from "../theme";
import { chromeMailvibesLink } from "../constants";

/**
 *
 * it shows links for the menu navbar
 * @returns {JSX.Element}
 */
export const MenuOptions = ({ links }) => {
  return (
    <Box
      sx={{
        flexGrow: 0,
        marginRight: ".5rem",
        display: { xs: "none", sm: "flex" },
      }}
    >
      {links.map((item, index) => (
        <Link
          key={index}
          href={item.link}
          target="_blank"
          underline="none"
          color="black"
          variant="body1"
          sx={{
            sm: "none",
            borderRadius: 8,
            marginRight: 1,
            textTransform: "capitalize",
            padding: "5px 8px",
            "&:hover": {
              backgroundColor: "rgba(90, 42, 245, 0.1)",
              transition: "all 0.2s ease",
            },
          }}
        >
          {item.name}
        </Link>
      ))}

      <Button
        color="mailvibes"
        variant="contained"
        sx={{
          borderRadius: 8,
          textTransform: "capitalize",
          marginRight: 3,
          fontSize: "0.8rem",
        }}
      >
        <Link
          color={theme.palette.white}
          underline="none"
          href={chromeMailvibesLink}
          target="_blank"
        >
          Install Free
        </Link>
      </Button>
    </Box>
  );
};
