/**
 * get data from localstorage
 * @param {string} key
 * @returns {Object} data
 */
export function getItem(key) {
  try {
    if (localStorage.getItem(key)) {
      return JSON.parse(localStorage.getItem(key) || "");
    } else {
      return null;
    }
  } catch (error) {
    console.log("error: ", error);
    return "";
  }
}

/**
 * set item in localstorage
 * @param {string} key
 * @param {*} value
 */
export function setItem(key, value) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.log("error: ", error);
  }
}
