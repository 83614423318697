/**
 * return boolean if the item has Template
 * @param {object} item
 * @return {boolean}
 */
export function hasTemplate(item) {
  return (
    item.MassMailRecipients?.length > 0 &&
    item.MassMailRecipients[0]?.Mail &&
    item.MassMailRecipients[0]?.Mail?.template_id
  );
}
