import { useState } from "react";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import TableCellWrapper from "../TableCellWrapper";
import { SelectInput } from "../../../components";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import {
  SuccessIcon,
  ScheduledIcon,
  RejectedIcon,
} from "../../../components/Icons";
import { downloadExcel } from "react-export-table-to-excel";

const loadingList = [{ id: 1 }];

/**
 * shows and hide content
 * @returns {JSX.Element}
 */
const CollapseWrapper = ({ isOpen, rows, loading }) => {
  const [selectStatus, setSelectStatus] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const filteredList = rows.filter((item) => {
    let result = false;
    const search = searchInput?.toLowerCase();
    const select = selectStatus?.toLowerCase();
    if (searchInput !== "") {
      result =
        item?.email?.toLowerCase()?.includes(search) ||
        item?.name?.toLowerCase()?.includes(search) ||
        item?.status?.toLowerCase()?.includes(search);
      return result;
    }

    if (selectStatus !== "") {
      result =
        item?.status?.toLowerCase()?.includes(select) ||
        (select === "all" && true);
      return result;
    }
    return true;
  });

  const list = loading ? loadingList : filteredList;

  const handleDownload = () => {
    const newList = filteredList.map((item) => {
      return [item.name, item.email, item.opened, item.status];
    });

    downloadExcel({
      fileName: `react-export-table-to-excel`,
      sheet: "react-export-table-to-excel",
      tablePayload: {
        header: ["name", "email", "opened", "status"],
        // accept two different data structures
        body: newList,
      },
    });
  };

  return (
    <Collapse
      in={isOpen}
      timeout="auto"
      unmountOnExit
      sx={{ height: 0, padding: 0, margin: "10px" }}
    >
      <Box
        sx={{
          margin: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button
          onClick={handleDownload}
          color="primary"
          variant="contained"
          sx={{ marginBottom: "5px", marginRight: "9px", height: "40px" }}
        >
          Export list
        </Button>
        <SelectInput
          label="Status"
          id="status-input"
          labelId="status-select"
          placeholder="Status"
          onChange={(event) => setSelectStatus(event.target.value)}
          value={selectStatus}
          items={[
            { value: "success", text: "Success" },
            { value: "scheduled", text: "Scheduled" },
            { value: "rejected", text: "Rejected" },
            { value: "all", text: "All" },
          ]}
        />

        <TextField
          value={searchInput}
          onChange={(event) => setSearchInput(event.target.value)}
          hiddenLabel
          id="filled-hidden-label-small"
          placeholder="search"
          variant="filled"
          size="small"
          sx={{ marginBottom: "6px", marginLeft: "9px" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box>
        <Table aria-label="purchases">
          <TableHead>
            <TableRow>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Opened</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.map((row) => (
              <TableRow key={row?.id}>
                <TableCellWrapper
                  loading={loading}
                  component="th"
                  scope="row"
                  align="center"
                >
                  {row?.email}
                </TableCellWrapper>
                <TableCellWrapper loading={loading} align="center">
                  {row?.name}
                </TableCellWrapper>
                <TableCellWrapper loading={loading} align="center">
                  {row?.views || 0}
                </TableCellWrapper>
                <TableCellWrapper loading={loading} align="center">
                  {row?.status === "success" ? (
                    <SuccessIcon />
                  ) : row?.status === "scheduled" ? (
                    <ScheduledIcon />
                  ) : (
                    <RejectedIcon />
                  )}
                </TableCellWrapper>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Collapse>
  );
};

export default CollapseWrapper;
