import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { mailvibesApi } from "../../api";

const initialState = {
  loading: false,
  notes: [],
  error: "",
};

export const fetchNotes = createAsyncThunk(
  "notes/fetchNotes",
  async (mailId, value) => {
    return mailvibesApi
      .get(`/v2/mail/${mailId}/notes`)
      .then((response) => response.data);
  }
);

export const editNote = createAsyncThunk(
  "notes/editNote",
  async ({ noteId, mail_id, note }) => {
    return mailvibesApi
      .put(`/v2/mail/note/${noteId}`, { mail_id, note })
      .then((response) => {
        return response.data;
      });
  }
);

export const deleteNote = createAsyncThunk(
  "notes/deleteNote",
  async (noteId) => {
    return mailvibesApi
      .delete(`/v2/mail/note/${noteId}`)
      .then((response) => response.data);
  }
);

export const newNote = createAsyncThunk("notes/newNote", async (data) => {
  return mailvibesApi
    .post("/v2/mail/note", data)
    .then((response) => response.data.data);
});

const notesSlice = createSlice({
  name: "notes",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchNotes.pending, (state) => {
      state.loading = true;
      state.notes = [];
      state.error = "";
    });
    builder.addCase(fetchNotes.fulfilled, (state, action) => {
      state.loading = false;
      state.notes = action.payload;
      state.error = "";
    });
    builder.addCase(fetchNotes.rejected, (state, action) => {
      state.loading = false;
      state.notes = [];
      state.error = action.error.message;
    });
    builder.addCase(editNote.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });

    builder.addCase(editNote.fulfilled, (state, action) => {
      state.loading = false;
      state.notes = state.notes.map((note) => {
        if (note.id === action?.meta?.arg?.noteId) {
          note.note = action.meta.arg.note;
          return note;
        }
        return note;
      });
      state.error = "";
    });
    builder.addCase(editNote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(deleteNote.pending, (state, action) => {
      state.loading = true;
      state.notes = state.notes.filter(
        (note) => note?.id !== action?.meta?.arg
      );
      state.error = "";
    });
    builder.addCase(deleteNote.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(deleteNote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(newNote.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(newNote.fulfilled, (state, action) => {
      state.loading = false;
      state.notes = [...state.notes, action.payload];
      state.error = "";
    });
    builder.addCase(newNote.rejected, (state, action) => {
      state.loading = false;
      state.error = "";
    });
  },
});

export default notesSlice.reducer;
