import { Box, CircularProgress } from "@mui/material";

export const PageLoader = ({ center, paddingTop }) => {
  return (
    <Box
      sx={{
        paddingTop: paddingTop,
        display: center && "flex",
        justifyContent: center && "center",
        alignItems: center && "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};
