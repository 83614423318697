import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  route: "Home",
};

const memoryRouteSlice = createSlice({
  name: "memoryRoute",
  initialState,
  reducers: {
    changeMainRoute: (state, action) => {
      state.route = action.payload;
    },
  },
});

export default memoryRouteSlice.reducer;
export const { changeMainRoute } = memoryRouteSlice.actions;
