import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { changeMainRoute } from "../../../redux/features/memoryRouteSlice";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Link } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { mainRouteLinks } from "../../../constants";
import { theme } from "../../../theme";
import { drawerWidth } from "../../../constants";
import getRoutePathname from "../../../utils/getRoutePathname";

/**
 * it shows a sidebar menu
 * @returns {JSX.Element}
 */
const SideBar = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const handleSetRoute = () => {
    dispatch(changeMainRoute(getRoutePathname(location.pathname, 1)));
  };

  return (
    <>
      <Drawer
        elevation={10}
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
          display: { xs: "none", sm: "block" },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto", marginTop: "59px" }}>
          <List>
            {mainRouteLinks.map((item, index) => (
              <Link
                to={item.link}
                onClick={() => handleSetRoute()}
                style={{ textDecoration: "none" }}
                key={index}
              >
                <ListItem disablePadding sx={{ textDecoration: "none" }}>
                  <ListItemButton
                    selected={
                      item.link === getRoutePathname(location.pathname, 1)
                    }
                  >
                    <ListItemIcon>
                      {
                        <item.icon
                          sx={{ color: theme.palette.mailvibes.secondary }}
                        />
                      }
                    </ListItemIcon>
                    <ListItemText
                      primary={item.name}
                      sx={{
                        color: theme.palette.mailvibes.secondary,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default SideBar;
