function createData(
  id,
  subject,
  sentAs,
  sent,
  notSent,
  opened,
  unsubscribed,
  bounced,
  createdDate
) {
  return {
    id,
    subject,
    sentAs,
    sent,
    notSent,
    opened,
    unsubscribed,
    bounced,
    createdDate,
  };
}

const exampleData = [
  createData(
    "8%&%rDF)P67P,.yG Nh:yhvjXF>V,;*WmptsT>*6MZua,h82/at36kx$Bg!Csu#u",
    "email enviado",
    "franklinserif@gmail.com",
    "N/A",
    1,
    0,
    0,
    0,
    "last Wednesday at 12:20 PM"
  ),
  createData(
    "QSN;:SFd$4=M(qUam<rM:v)Z2XsS7n*!n7#9UE<_$E9#PPTwvzZQB4(&Bfe&sa&6",
    "email test 10 ",
    "franklinserif@gmail.com",
    "N/A",
    1,
    1,
    0,
    0,
    "last Wednesday at 12:30 PM"
  ),
  createData(
    "cLi1h4JCbvW26xlXGOQR0FdKjMBqrzSYHTsakV9gwUZf7o8n3tIuPNepyDA5mE",
    "emails enviados",
    "franklinserif@gmail.com",
    "N/A",
    1,
    0,
    1,
    0,
    "last Wednesday at 09:20 AM"
  ),
  createData(
    "IJyjwVMF7Wk3UpxRuK2vmS0aGgBQe4XoLEsiYzq8tdNCDncPAOT5bfr91Hl6hZ",
    "email",
    "franklinserif@gmail.com",
    "N/A",
    1,
    0,
    3,
    0,
    "last Wednesday at 1:20 PM"
  ),
  createData(
    "RWCPvZG8Wg2P2y9cKunSrtT7yUGZ7nM7",
    "email test prueba",
    "franklinserif@gmail.com",
    "N/A",
    1,
    0,
    1,
    0,
    "last Wednesday at 1:20 PM"
  ),
  createData(
    "P9z2MQkKhODBHjFlRTNxtJWcGdVSCfw5eaZX7is3oAIuyqLm1rbEp0gYvU4n86",
    "email enviados",
    "franklinserif@gmail.com",
    "N/A",
    1,
    0,
    2,
    2,
    "last Wednesday at 1:20 PM"
  ),
  createData(
    "FOlPZ5TijUaEqWhgR1k4IHCBnD87obcy0pxruKJvLNVs9A3SedY6XtzGmfM2Qw",
    "email 2",
    "franklinserif@gmail.com",
    "N/A",
    1,
    0,
    0,
    1,
    "last Wednesday at 1:20 PM"
  ),
  createData(
    "P9C7ZcgofdhzGH1eBkVsxNAO3aXRFbvIE5pS2r6lYyjWmnMtL4UuwJTDQi8Kq0",
    "email 3",
    "franklinserif@gmail.com",
    "N/A",
    1,
    0,
    0,
    0,
    "last Wednesday at 1:20 PM"
  ),
  createData(
    "2U0q6zktAINOYwTfrDGspaHCJvo1h5FiSB8KLmEuQ73XgPx4ZMnbVRWjecdl9y",
    "email enviado test 2",
    "franklinserif@gmail.com",
    "N/A",
    1,
    0,
    0,
    0,
    "last Wednesday at 1:20 PM"
  ),
  createData(
    "UeVSbxRXLI9njNZzEsB7CDvrOQ81gcy2dmkJhwPaAY4TqGtHMK3oi5lFf6up0W",
    "email enviado test 3",
    "franklinserif@gmail.com",
    "N/A",
    1,
    0,
    0,
    0,
    "last Wednesday at 1:20 PM"
  ),
  createData(
    "XvQxD1BzIY0pLFfqh36gsyn4HcSRuCAUJT2PraZ5wNK7biWd8eljGoVtOM9kmE",
    "email enviado test 4",
    "franklinserif@gmail.com",
    "N/A",
    1,
    0,
    2,
    0,
    "last Wednesday at 1:20 PM"
  ),
  createData(
    "UB7Wb68cImrpj0LX3JSgwas412DPCeG5TxVHoziAqNYuv9tdyOZQERfhFlKnMk",
    "email enviado test 5",
    "franklinserif@gmail.com",
    "N/A",
    1,
    0,
    0,
    0,
    "last Wednesday at 1:20 PM"
  ),
  createData(
    "zP3ngEeNIfaYhcu2CFyOmZ409dsWjHv51XBkqLbUMt7woxiR6Ar8JTKGpDlSVQ",
    "email enviado test 6",
    "franklinserif@gmail.com",
    "N/A",
    1,
    0,
    0,
    0,
    "last Wednesday at 1:20 PM"
  ),
];

export default exampleData;
