import React, { useId, useState } from "react";
import {
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
} from "@mui/material";
import AddIcon from "@mui/icons-material/AddBoxRounded";
import { snackbar } from "../utils";

export const SelectWithAddOption = ({ options, placeHolder, size }) => {
  const id = useId();
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (event) => {
    setSelectedOptions(event.target.value);
  };

  const handleAddOptionClick = () => {
    // Agrega aquí la lógica para agregar una nueva opción.
    // Puedes abrir un modal o mostrar otro input según tus necesidades.
    snackbar("Agregar nueva opción", "error");
  };

  return (
    <FormControl sx={{ minWidth: "250px" }} size={size}>
      <InputLabel id={id}>{placeHolder}</InputLabel>
      <Select
        size={size}
        label={placeHolder}
        labelId={id}
        multiple
        value={selectedOptions}
        onChange={handleChange}
        renderValue={(selected) =>
          selected?.map((value) => (
            <Chip key={value} label={options[value].text} size={size} />
          ))
        }
        MenuProps={{ PaperProps: { style: { maxHeight: "300px" } } }}
      >
        {options.map(({ text }, index) => (
          <MenuItem key={`option-${index}`} value={index}>
            <ListItemIcon>
              <Checkbox checked={selectedOptions.indexOf(index) > -1} />
            </ListItemIcon>
            {text}
          </MenuItem>
        ))}
        <MenuItem onClick={handleAddOptionClick} sx={{ paddingLeft: "27px" }}>
          <AddIcon sx={{ marginRight: "9px" }} /> Add a Column
        </MenuItem>
      </Select>
    </FormControl>
  );
};
