import { Box, Typography, Divider } from "@mui/material";
import { MUXSwitch } from "./";
import { theme } from "../theme";

export const DoubleDescriptionSwitch = ({ isActive, setIsActive }) => {
  const handleChecked = () => {
    setIsActive();
  };
  return (
    <Box
      sx={{
        padding: "1.2rem 0",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "5px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "14.4px",
              color: theme.palette.grays.dark150,
              marginLeft: "5px",
            }}
          >
            Activate this function by default in emails
          </Typography>
        </Box>

        <MUXSwitch
          onChange={handleChecked}
          checked={isActive}
          label={isActive ? "Activated" : "Disabled"}
          labelPlacement="start"
        />
      </Box>
      <Divider />
      <Typography
        variant="h6"
        sx={{
          fontSize: "14.4px",
          color: theme.palette.grays.dark150,
          marginLeft: "5px",
          marginTop: "10px",
        }}
      >
        Where do you get these notifications
      </Typography>
    </Box>
  );
};
