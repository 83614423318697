import { forwardRef } from "react";
import {
  Box,
  Button,
  Link,
  Dialog,
  Typography,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import * as XLSX from "xlsx";
import { theme } from "../theme";
import { arrayHasProperty, snackbar } from "../utils";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ImportModal = ({ open, setOpen, handleImport }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (evt) => {
      // Parse data del archivo:
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });

      // Obtiene el primer worksheet:
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      // Convierte el arreglo a JSON:
      const jsonData = XLSX.utils.sheet_to_json(ws);

      if (arrayHasProperty(jsonData, "Email")) {
        handleImport({ rows: jsonData, name: file.name });
      } else {
        snackbar("error: property not found", "error");
      }
    };

    reader.readAsBinaryString(file);
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle component="div">
          <Typography variant="h6">Load your contactact List</Typography>

          <Typography variant="subtitle1" color="primary">
            Import an XLS, XLSX or CSV document (5MB max.)
          </Typography>
        </DialogTitle>

        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            component="div"
          >
            <Box>
              <Typography variant="subtitle2">
                Use the headings in each column to identify the fields they want
                to use. The first column must always be email.
              </Typography>
              <br />
              <Typography variant="subtitle2">
                We recommend using our template to make sure your data is in the
                correct format.
              </Typography>

              <Link
                href="https://cdn.mvib.es/assets/example.xlsx"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  marginTop: 1,
                }}
              >
                <CloudDownloadIcon fontSize="small" sx={{ marginRight: 1 }} />
                Download template
              </Link>

              <Box
                sx={{
                  width: "550px",
                  height: "147px",
                  backgroundColor: theme.palette.mailvibes.fourting,
                  border: `2px dashed ${theme.palette.mailvibes.tertiary}`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 1,
                  "&:hover": {
                    backgroundColor: theme.palette.mailvibes.lighter,
                    borderColor: theme.palette.mailvibes.darker,
                  },
                }}
              >
                <Button
                  variant="contained"
                  component="label"
                  sx={{ cursor: "pointer" }}
                >
                  Click or Drop files to upload
                  <input
                    hidden
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    multiple
                    type="file"
                    onChange={handleFileUpload}
                  />
                </Button>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ paddingBottom: 3, paddingRight: 3 }}>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} variant="contained">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
