import { logout, signin } from "../features/authSlice";
import { sendComposeMail } from "../features/composeAISlice";
import { axiosRemovetoken } from "../../api";
import { snackbar, setItem } from "../../utils";
import { configureAxiosInterceptor } from "../../api/axiosInterceptor";

export const authMiddleware = (store) => (next) => (action) => {
  if (logout.match(action)) {
    localStorage.removeItem("auth");
    axiosRemovetoken();
  }
  if (signin.fulfilled.match(action)) {
    const auth = store.getState((state) => state).auth;
    const newAuth = { ...auth, user: action.payload };
    setItem("auth", newAuth);
    configureAxiosInterceptor(newAuth);
  }

  return next(action);
};

export const errorHandlingMiddleware =
  (storeAPI) => (next) => async (action) => {
    if (!action.type.endsWith("/rejected")) {
      return next(action);
    }
    snackbar(`error: ${action?.error?.message}`, "error");
    return next(action);
  };

export const sendEmailSuccess = (storeAPI) => (next) => async (action) => {
  if (sendComposeMail.fulfilled.match(action)) {
    snackbar(`email sent success`);
  }
  return next(action);
};
