import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { mailvibesApi } from "../../api";

const initialState = {
  loading: false,
  distribution: [],
  error: "",
};

export const getDistributionUser = createAsyncThunk(
  "distributionUser/getDistributionUser",
  async () => {
    return mailvibesApi
      .get("/v2/distribution/user")
      .then((response) => response.data)
      .catch((error) => error.message);
  }
);

const distributionUserSlice = createSlice({
  name: "company",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDistributionUser.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getDistributionUser.fulfilled, (state, action) => {
      state.loading = false;
      state.distribution = action.payload;
      state.error = "";
    });
    builder.addCase(getDistributionUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default distributionUserSlice.reducer;
