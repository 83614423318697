import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { mailvibesApi } from "../../api";

const initialState = {
  mails: [],
  loading: true,
  error: "",
  total: 0,
};

export const fetchSentMails = createAsyncThunk(
  "sentMails/fetchSentMails",
  async ({ status, search, date, rowsPerPage }) => {
    return mailvibesApi
      .get(
        `/v2/mail/mass?limit=${rowsPerPage}&page=1&mindate=${
          date[0] === undefined ? "" : date[0]
        }&maxdate=${date[1] === undefined ? "" : date[1]}&search=${
          search ? search : ""
        }&status=${status ? status : "all"}`
      )
      .then((response) => response.data);
  }
);

const sentMailsSlice = createSlice({
  name: "sentMails",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchSentMails.pending, (state) => {
      state.loading = true;
      state.mails = [];
      state.error = "";
    });
    builder.addCase(fetchSentMails.fulfilled, (state, action) => {
      state.loading = false;
      state.mails = action.payload?.mails;
      state.total = action.payload.pages;
      state.error = "";
    });
    builder.addCase(fetchSentMails.rejected, (state, action) => {
      state.loading = false;
      state.mails = [];
      state.error = action.error.message;
    });
  },
});

export default sentMailsSlice.reducer;
