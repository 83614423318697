import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  summaryText: "",
  summaryLoading: false,
  transcriptionText: "",
  transcriptionLoading: false,
  process: "",
  error: "",
  trancritptionView: true,
  summaryView: true,
  transcriptionFinish: false,
  summaryFinish: false,
};

const summarizerSlice = createSlice({
  name: "summarizer",
  initialState,
  reducers: {
    beginTranscription: (state, action) => {
      state.summaryLoading = true;
      state.transcriptionLoading = true;
      state.summaryText = "";
      state.transcriptionText = "";
      state.process = `Listening to audio and analyzing the ${
        action.payload ? action.payload[0] : "information"
      }...`;
      state.trancritptionView = true;
      state.summaryView = true;
      state.transcriptionFinish = false;
      state.summaryFinish = false;
    },
    setTranscription: (state, action) => {
      state.transcriptionLoading = false;
      state.transcriptionText = action.payload;
      state.process = "Summarizing file";
      state.transcriptionFinish = true;
    },
    setSummary: (state, action) => {
      state.summaryLoading = false;
      state.summaryText = action.payload;
      state.process = "";
      state.summaryFinish = true;
    },
    setError: (state, action) => {
      state.summaryLoading = false;
      state.transcriptionLoading = false;
      state.process = "";
      state.error = action.payload;
      state.trancritptionView = false;
      state.summaryView = false;
      state.transcriptionFinish = false;
      state.summaryFinish = false;
    },
    endProccess: (state) => {
      state.summaryLoading = false;
      state.transcriptionLoading = false;
    },
    setSummaryView: (state, action) => {
      state.summaryView = action.payload;
    },
    setTranstiptionView: (state, action) => {
      state.trancritptionView = action.payload;
    },
  },
});

export default summarizerSlice.reducer;
export const {
  beginTranscription,
  setTranscription,
  setSummary,
  setError,
  endProccess,
  setSummaryView,
  setTranstiptionView,
} = summarizerSlice.actions;
