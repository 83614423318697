import axios from "axios";
import { setItem } from "../utils";
import jwt_decode from "jwt-decode";

export const refreshAccessToken = async (data) => {
  try {
    const user = jwt_decode(data?.user?.access_token || data?.access_token);
    const response = await axios.post(
      process.env.REACT_APP_API + "/auth/session",

      { user_id: user.id }
    );
    const newUserData = {
      ...data,
      user: { ...data.user, access_token: response.data.access_token },
    };
    setItem("auth", newUserData);
    return newUserData;
  } catch (error) {
    console.log("error refresh token", error);
  }
};
