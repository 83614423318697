import { useDispatch } from "react-redux";
import { logout } from "../redux/features/authSlice";
import { Typography } from "@mui/material";
import { googleLogout } from "@react-oauth/google";

/**
 * it show the logout option and handle
 * the logout functions
 * @returns {JSX.Element}
 */
export const GoogleLogoutButton = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    googleLogout();
  };

  return (
    <>
      <Typography textAlign="center" onClick={handleLogout}>
        Logout
      </Typography>
    </>
  );
};
