import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { fetchSentMails } from "../../redux/features/sentMails";
import { changeOpen } from "../../redux/features/TemplateSlice";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import FilterButton from "./FilterButton";
import Row from "./Row";
import MUXDialog from "../MUXDialog";
import { visuallyHidden } from "@mui/utils";
import { getComparator, stableSort, getMails, exampleData } from "../../utils";
import parse from "html-react-parser";

import "./style.css";

const headCells = [
  {
    id: "subject",
    numeric: false,
    disablePadding: true,
    label: "Subject",
  },
  {
    id: "sentAs",
    numeric: false,
    disablePadding: false,
    label: "Sent as",
  },
  {
    id: "sent",
    numeric: true,
    disablePadding: false,
    label: "Sent",
  },
  {
    id: "notSent",
    numeric: true,
    disablePadding: false,
    label: "Not Sent",
  },
  {
    id: "viewed",
    numeric: true,
    disablePadding: false,
    label: "Opened",
  },
  {
    id: "unsubscribed",
    numeric: true,
    disablePadding: false,
    label: "Unsubscribed",
  },
  {
    id: "bounced",
    numeric: true,
    disablePadding: false,
    label: "Bounced",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Created date",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={
              index <= 1
                ? "left"
                : index === headCell.length - 1
                ? "center"
                : "center"
            }
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Sent mailing list
        </Typography>
      )}

      <FilterButton rowsPerPage={props?.rowsPerPage} />
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function DataTable() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("subject");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const sentMails = useSelector((state) => state.sentMails);
  const mails = getMails(sentMails?.mails);
  const template = useSelector((state) => state.template);

  useEffect(() => {
    dispatch(
      fetchSentMails({
        search: undefined,
        status: undefined,
        date: [undefined, undefined],
        rowsPerPage,
      })
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = mails?.map((n) => n.subject);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const list = sentMails?.loading ? exampleData?.slice(0, rowsPerPage) : mails;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, overflow: "hidden" }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          rowsPerPage={rowsPerPage}
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={list?.length}
            />
            <TableBody>
              {stableSort(list, getComparator(order, orderBy)).map(
                (row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <Row
                      row={row}
                      MassMailRecipients={row?.MassMailRecipients}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                      handleClick={handleClick}
                      key={row.id}
                    />
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={sentMails.total * rowsPerPage}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        {!template.loading && (
          <MUXDialog
            open={template.isOpen}
            setOpen={(value) => dispatch(changeOpen(value))}
            title={template.content.title}
            closeText="close Template"
          >
            {template.content.html !== undefined
              ? parse(template.content.html)
              : ""}
          </MUXDialog>
        )}
      </Paper>
    </Box>
  );
}
