function getLetters(mailAddress) {
  const justName = mailAddress?.split("@")[0];
  const words = justName?.split(".");
  let avatarLetters = "";

  if (words.length > 0) {
    words.forEach((word) => {
      const letters = word[0]?.split("")[0];
      avatarLetters = avatarLetters + letters;
    });
  } else {
    avatarLetters = words;
  }

  return avatarLetters?.toUpperCase();
}

export default function getMailLetter(mailAddress) {
  const letters = mailAddress?.map((lt) => {
    return { name: lt?.email, letters: getLetters(lt.email) };
  });
  return letters;
}
