/**
 * return a pathname
 * @param {string} pathname
 * @param {number} level
 * @returns {string}
 */
const getRoutePathname = (pathname, level) => {
  let path = pathname.split("/")[level];

  if (!path) {
    path = pathname.split("/")[1];
  }

  return path;
};

export default getRoutePathname;
