import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

export const MUXSwitch = ({ label, onChange, checked, labelPlacement }) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch checked={checked} onChange={onChange} />}
        label={label}
        labelPlacement={labelPlacement}
      />
    </FormGroup>
  );
};

export default MUXSwitch;
