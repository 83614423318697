import DoneAllIcon from "@mui/icons-material/DoneAll";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import SettingsIcon from "@mui/icons-material/Settings";
import AppsIcon from "@mui/icons-material/Apps";

export const chromeMailvibesLink =
  "https://chrome.google.com/webstore/detail/email-tracking-for-gmail/makgoeakbbkhogecikabicopabombggi";

export const menuLinks = [
  { link: "https://mailvibes.io/pricing/", name: "pricing" },
  { link: "https://mailvibes.io/blog/", name: "Blog" },
  { link: "https://mailvibes.io/help-center/", name: "Help Center" },
];

export const menuSettingsOptions = [
  { name: "account", link: "/mailvibes-settings/account" },
  { name: "settings", link: "/mailvibes-settings" },
];

export const mainRouteLinks = [
  { link: "email-tracking", name: "Email Tracking", icon: DoneAllIcon },
  { link: "mass-email", name: "Mass Email", icon: DraftsIcon },
  { link: "sequence", name: "Sequence", icon: SendIcon },
  {
    link: "mailvibes-settings",
    name: "MailVibes Settings",
    icon: SettingsIcon,
  },
  {
    link: "integrations",
    name: "Integrations",
    icon: AppsIcon,
  },
];

export const homeRouteLink = [{ link: "/", name: "Home" }];
export const emailTrackingRouteLink = [
  { link: "/email-tracking", name: "Email Tracking Stats" },
  { link: "/email-tracking/link-tracking-stats", name: "Link Tracking Stats" },
];
export const massEmailRouteLink = [
  {
    link: "/mass-email",
    name: "Mass Email",
  },
  {
    link: "/mass-email/sent-mails",
    name: "sent mail",
  },
  {
    link: "/mass-email/analytics",
    name: "Analytics",
  },

  {
    link: "/mass-email/distribution",
    name: "Distribution Lists",
  },
  { link: "/mass-email/templates", name: "Templates" },
];
export const sequenceRouteLink = [
  { link: "/sequence", name: "Sequence" },
  { link: "/sequence/sent-sequence", name: "Sent Sequence" },
  { link: "/sequence/distribution", name: "Distribution Lists" },
  { link: "/sequence/templates", name: "Templates" },
];

export const mailvibesSettingsRouteLink = [
  { link: "/mailvibes-settings/company", name: "Company" },
  { link: "/mailvibes-settings/account", name: "Account" },
  { link: "/mailvibes-settings", name: "Settings" },
];

export const integrationsRouteLink = [
  { link: "/integrations", name: "ComposeAI" },
  { link: "/integrations/summarizer", name: "Summarizer" },
];
