/**
 * check if the emails has template
 * @param {object} email
 * @returns {boolean}
 */
export default function hasTemplate(email) {
  return (
    email.MassMailRecipients.length > 0 &&
    email.MassMailRecipients[0].Mail &&
    email.MassMailRecipients[0].Mail.template_id
  );
}
