import React from "react";

import { SuspenseWrapper } from "../containers";

const MailvibesSettings = React.lazy(() =>
  import("../pages/MailvibesSettings/settings/SettingsPage")
);
const Company = React.lazy(() => import("../pages/MailvibesSettings/Company"));
const Account = React.lazy(() =>
  import("../pages/MailvibesSettings/Account/AccountPage")
);

const mailvibesSettingsRouter = [
  {
    path: "",
    element: (
      <SuspenseWrapper>
        <MailvibesSettings />
      </SuspenseWrapper>
    ),
  },
  {
    path: "company",
    element: (
      <SuspenseWrapper>
        <Company />,
      </SuspenseWrapper>
    ),
  },
  {
    path: "account",
    element: (
      <SuspenseWrapper>
        <Account />,
      </SuspenseWrapper>
    ),
  },
];

export default mailvibesSettingsRouter;
