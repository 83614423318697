import TableCell from "@mui/material/TableCell";
import Skeleton from "@mui/material/Skeleton";

/**
 * switch between loading and children component
 * @param {object} props
 * @param {object} props.children
 * @param {object} props.loading
 * @returns {JSX.Element}
 */
const TableCellWrapper = ({ children, loading, ...rest }) => {
  return <TableCell {...rest}>{loading ? <Skeleton /> : children}</TableCell>;
};

export default TableCellWrapper;
