import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { mailvibesApi } from "../../api";

const initialState = {
  content: { html: undefined, title: undefined },
  isOpen: false,
  loading: false,
  columns: [],
  error: "",
};

export const fetchTemplate = createAsyncThunk(
  "template/fetchTemplate",
  async (templateId) => {
    return mailvibesApi
      .get(`/v2/template/${templateId}`)
      .then((response) => response.data);
  }
);

export const editTemplate = createAsyncThunk(
  "template/editTemplate",
  async ({ id, data }) => {
    return mailvibesApi
      .put(`/v2/template/${id}`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => error.message);
  }
);

export const getTemplateDistributionColumns = createAsyncThunk(
  "template/getTemplateDistributionColumns",
  async (id) => {
    return mailvibesApi
      .get(`/v2/distribution/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => error.message);
  }
);

export const createNewTemplate = createAsyncThunk(
  "template/createNewTemplate",
  async (data) => {
    return mailvibesApi
      .post("/v2/template", data)
      .then((response) => response.data)
      .catch((error) => error.message);
  }
);

const templateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {
    changeOpen: (state, action) => {
      state.isOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTemplate.pending, (state) => {
      state.loading = true;
      state.isOpen = false;
      state.content = { html: undefined, title: undefined };
      state.error = "";
    });
    builder.addCase(fetchTemplate.fulfilled, (state, action) => {
      state.loading = false;
      state.content = {
        ...action.payload,
        html: action.payload?.content,
        title: action?.payload?.title,
      };
      state.isOpen = true;
      state.error = "";
    });
    builder.addCase(fetchTemplate.rejected, (state, action) => {
      state.loading = false;
      state.content = { html: undefined, title: undefined };
      state.isOpen = false;
      state.error = action?.error.message;
    });
    builder.addCase(editTemplate.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(editTemplate.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(editTemplate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getTemplateDistributionColumns.pending, (state) => {
      state.error = "";
    });
    builder.addCase(
      getTemplateDistributionColumns.fulfilled,
      (state, action) => {
        state.loading = false;
        state.columns =
          action.payload?.columns.map((column, index) => ({
            id: index,
            value: column,
          })) || [];
        state.error = "";
      }
    );
    builder.addCase(
      getTemplateDistributionColumns.rejected,
      (state, action) => {
        state.loading = false;
        state.columns = [];
        state.error = action.payload;
      }
    );
    builder.addCase(createNewTemplate.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(createNewTemplate.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(createNewTemplate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.message;
    });
  },
});

export default templateSlice.reducer;
export const { changeOpen } = templateSlice.actions;
