const templateTableHead = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "subject",
    numeric: false,
    disablePadding: false,
    label: "Subject",
  },
  {
    id: "shortcode",
    numeric: false,
    disablePadding: false,
    label: "Shortcode",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "owner",
    numeric: false,
    disablePadding: false,
    label: "Owner",
  },
  {
    id: "lastUpdate",
    numeric: false,
    disablePadding: false,
    label: "Last Update",
  },
];

const templateRowsExample = [
  createData("Cupcake", "Cupcake", "Cupcake", "Cupcake", "Cupcake", "Cupcake"),
  createData("Donut", "Donut", "Donut", "Donut", "Donut", "Cupcake"),
  createData("Eclair", "Eclair", "Eclair", "Eclair", "Eclair", "Cupcake"),
  createData(
    "Frozen yoghurt",
    "Frozen yoghurt",
    "Frozen yoghurt",
    "Frozen yoghurt",
    "Frozen yoghurt",
    "Cupcake"
  ),
  createData(
    "Gingerbread",
    "Gingerbread",
    "Gingerbread",
    "Gingerbread",
    "Gingerbread",
    "Cupcake"
  ),
  createData(
    "Honeycomb",
    "Honeycomb",
    "Honeycomb",
    "Honeycomb",
    "Honeycomb",
    "Cupcake"
  ),
  createData(
    "Ice cream sandwich",
    "Ice cream sandwich",
    "Ice cream sandwich",
    "Ice cream sandwich",
    "Ice cream sandwich",
    "Cupcake"
  ),
  createData(
    "Jelly Bean",
    "Ice cream sandwich",
    "Ice cream sandwich",
    "Ice cream sandwich",
    "Ice cream sandwich",
    "Cupcake"
  ),
  createData(
    "KitKat",
    "Ice cream sandwich",
    "Ice cream sandwich",
    "Ice cream sandwich",
    "Ice cream sandwich",
    "Cupcake"
  ),
  createData(
    "Lollipop",
    "Ice cream sandwich",
    "Ice cream sandwich",
    98,
    0.0,
    "Cupcake"
  ),
  createData("Marshmallow", 318, 0, 81, 2.0, "Cupcake"),
  createData("Nougat", 360, 19.0, 9, 37.0, "Cupcake"),
  createData("Oreo", 437, 18.0, 63, 4.0, "Cupcake"),
];

function createData(name, subject, short_code, type, owner, lastUpdate) {
  return {
    name,
    subject,
    short_code,
    type,
    owner,
    lastUpdate,
  };
}

export { templateTableHead, templateRowsExample };
