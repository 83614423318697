import { mailvibesApi } from "../api";

const isFreeUser = async (account) => {
  let { current_plan, end_date, name, MemberSubscriptions } = account.settings;
  let Subscriptions = { current_plan, name, end_date };
  if (current_plan !== undefined && end_date) {
    if (new Date() > new Date(Subscriptions[0].end_date)) {
      const result = await setCurrentSubscriptions();
      Subscriptions = result.subscriptions;
      MemberSubscriptions = result.memberSubscriptions;
    }
  } else if (
    MemberSubscriptions &&
    MemberSubscriptions.length &&
    MemberSubscriptions[0].end_date
  ) {
    if (new Date() > new Date(MemberSubscriptions[0].end_date)) {
      const result = await setCurrentSubscriptions();
      Subscriptions = result.subscriptions;
      MemberSubscriptions = result.memberSubscriptions;
    }
  }
  return !(
    (Subscriptions && Subscriptions.length) ||
    (MemberSubscriptions && MemberSubscriptions.length)
  );
};

const setCurrentSubscriptions = async () => {
  const { data } = await mailvibesApi.get("user/subscriptions");
  return {
    subscriptions: data.Subscriptions,
    memberSubscriptions: data.MemberSubscriptions,
  };
};

export default isFreeUser;
