import parseDateRelative from "../parseDateRelative";

/**
 * Get current subscription info
 * @returns {object} Formed by { name, startDate, endDate. isForMembers }
 */
const getCurrentSubscriptionInfo = (user) => {
  let name = "free";
  let startDate = user.settings.createdAt;
  let endDate = "forever";
  let isForMembers = false;

  if (user.settings?.current_plan) {
    name = `${user.settings.current_plan}${
      user.settings.status === "trialing" ? " (Trial)" : ""
    }`;

    startDate = user.settings.start_date;
    if (!name.includes("Lifetime")) {
      endDate = parseDateRelative(user.settings.valid_until);
    }
  } else if (
    user.settings?.MemberSubscriptions &&
    user.settings?.MemberSubscriptions?.length
  ) {
    isForMembers = true;
    name = `${user.settings.MemberSubscriptions[0].Pricing.name}${
      user.MemberSubscriptions[0].status === "trialing" ? " (Trial)" : ""
    }`;
    startDate = user.settings.MemberSubscriptions[0].start_date;
    if (!name.includes("Lifetime")) {
      endDate = parseDateRelative(
        user.settings.MemberSubscriptions[0].end_date
      );
    }
  }
  const buttonLabel =
    name === "Free"
      ? user.settings.stripe_customer_id
        ? "renew"
        : "upgrade_now"
      : "update";

  const newUser = {
    ...user,
    settings: {
      ...user.settings,
      current_plan: name,
      start_date: startDate ? parseDateRelative(startDate) : startDate,
      valid_until: endDate,
      buttonLabel,
      isForMembers,
    },
  };

  return newUser;
};

export default getCurrentSubscriptionInfo;
