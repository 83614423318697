import { Controller } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const langs = ["auto", "English", "Spanish"];

export const Language = ({ control, size = "medium", sx }) => (
  <FormControl
    fullWidth
    variant="outlined"
    sx={{ mb: "0.5rem", ...sx }}
    size={size}
  >
    <InputLabel htmlFor="language">language</InputLabel>
    <Controller
      name="language"
      control={control}
      defaultValue="auto"
      render={({ field }) => (
        <Select {...field} label="language" id="language" fullWidth size={size}>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {langs.map((lang) => (
            <MenuItem value={lang} key={lang}>
              {lang}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  </FormControl>
);
